const dev = {
    x: 'x',
    environment: 'dev'
}

const test = {
    x: 'y',
    environment: 'test'
}

const prod = {
    x: 'z',
    environment: 'live'
}

const config = process.env.REACT_APP_ENVIRONMENT === 'production'
  ? prod
  : dev;

  export default {
    ...config
  };