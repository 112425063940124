/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ReactDatetime from "react-datetime";

import { remediApi } from '../../../remediApi';

import NotificationAlert from "react-notification-alert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
          entries.
        </label>
    </div>
  )
});

const { SearchBar } = Search;

class PatientOrderDetails extends React.Component {
  state = {
    orderNumber: "",
    orderDate: "",
    orderStatus: "",
    phoneNumber: "",
    address: "",
    postcode: "",
    rxMethod: "",
    deliveryNotes: "",
    surgery: "",
    medications: [],
    patientId: "",
    loading: true
  };

  async componentDidMount() {
    let mojoId = this.props.location.search;
    let data = await remediApi.getPatientOrderDetails(mojoId);

    let order = data[0];
    this.setState({
      id: order.MojoId,
      version: order.MojoVersion,
      orderNumber: order.orderNumber,
      orderDate: order.orderDate,
      orderStatus: order.orderStatus,
      rxMethod: order.rxMethod,
      deliveryNotes: order.deliveryNotes,
      address: order.address,
      postcode: order.postcode,
      medications: order.medications,
      patientId: order.patientId,
      patientName: order.patientName,
      patientDob: order.patientDob,
      patientEmail: order.patientEmail,
      pharmacyId: order.pharmacyId,
      surgery: order.surgery,
      surgeryAddress: order.surgeryAddress,
      dispenseDate: order.dispenseDate,
      loading: false,
      editRxDate: false
    });
  }

  async _updateRxDate(date) {
    var result = await remediApi.updateRxDate(
      this.state.id,
      this.state.version,
      date,
      this.state.patientName,
      this.state.orderNumber,
      this.state.patientEmail,
      this.state.pharmacyId);
    if (result != null) {
      this.setState(prevState => ({ version: prevState.version + 1, dispenseDate: date, editRxDate: false }))

      let options = {
        place: 'br',
        message: (
          <div className="alert-text">
            <span className="alert-title" data-notify="title">
              {" "}
              Update Collection/Delivery date
            </span>
            <span data-notify="message">
              Collection/Delivery date has been updated successfully.
            </span>
          </div>
        ),
        type: "success",
        icon: "ni ni-check-bold",
        autoDismiss: 7
      };
      this.refs.notificationAlert.notificationAlert(options);
    } else {
      this.setState({ editRxDate: false })
      let options = {
        place: 'br',
        message: (
          <div className="alert-text">
            <span className="alert-title" data-notify="title">
              {" "}
              Update Collection/Delivery date
            </span>
            <span data-notify="message">
              Collection/Delivery failed to update, please try again.
            </span>
          </div>
        ),
        type: "danger",
        icon: "ni ni-fat-remove",
        autoDismiss: 7
      };
      this.refs.notificationAlert.notificationAlert(options);
    }
  }

  render() {
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <SimpleHeader name="Order Details" parentName="Orders" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0">{this.state.loading ?
                      <Spinner color="primary" /> :
                      <span>{this.state.orderNumber}</span>}
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <Form className="needs-validation" noValidate>
                      <div className="form-row">
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Order Number
                          </label>
                          <Input
                            type="text"
                            value={this.state.orderNumber}
                            readOnly={true}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </Col>
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom02"
                          >
                            Order Date
                          </label>
                          <Input
                            type="text"
                            value={this.state.orderDate}
                            readOnly={true}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </Col>
                      </div>
                      <div className="form-row">
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Patient Name
                          </label>
                          <Input
                            type="text"
                            value={this.state.patientName}
                            readOnly={true}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </Col>
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom02"
                          >
                            Patient Date of Birth
                          </label>
                          <Input
                            type="text"
                            value={this.state.patientDob}
                            readOnly={true}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </Col>
                      </div>
                      <div className="form-row">
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Order Status
                          </label>
                          <Input
                            type="text"
                            value={this.state.orderStatus == 'delivered' || this.state.orderStatus == 'collected' ? 'Processed' : 'Received'}
                            readOnly={true}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </Col>
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom02"
                          >
                            Collection/Delivery
                          </label>
                          <Input
                            type="text"
                            value={this.state.rxMethod}
                            readOnly={true}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </Col>
                        {this.state.orderStatus == 'delivered' || this.state.orderStatus == 'collected' ?
                          <Col className="mb-3" md="4">
                            <label
                              className="form-control-label"
                              htmlFor="validationCustom02"
                            >
                              Collection/Delivery Date
                          </label>
                            <InputGroup>
                              <ReactDatetime
                                className="dispense-date"
                                inputProps={{
                                  disabled: !this.state.editRxDate
                                }}
                                value={this.state.dispenseDate}
                                closeOnSelect={true}
                                dateFormat="YYYY-MM-DD"
                                onChange={(e) => {
                                  this._updateRxDate(e.format('YYYY-MM-DD'));
                                }}
                                isValidDate={(current) => {
                                  return current.isAfter(this.state.dispenseDate);
                                }}
                                timeFormat={false}
                              />
                              <InputGroupAddon addonType="append">
                                <Button
                                  className="btn btn-info btn-sm process-btn"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState(prevState => ({
                                      editRxDate: !prevState.editRxDate
                                    }), () => {
                                      document.querySelector("div.dispense-date input[type=text]").focus();
                                    }
                                    );
                                  }}>{this.state.editRxDate ? 'Cancel' : 'Update'}</Button>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col> : <Container />}
                      </div>
                      <div className="form-row">
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom03"
                          >
                            Address
                          </label>
                          <Input
                            type="text"
                            value={this.state.address}
                            readOnly={true}
                          />
                          <div className="invalid-feedback">
                            Please provide a valid city.
                          </div>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom03"
                          >
                            Postcode
                          </label>
                          <Input
                            type="text"
                            value={this.state.postcode}
                            readOnly={true}
                          />
                          <div className="invalid-feedback">
                            Please provide a valid city.
                          </div>
                        </Col>
                      </div>
                      <div className="form-row">
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom03"
                          >
                            Surgery
                          </label>
                          <Input
                            type="text"
                            value={this.state.surgery}
                            readOnly={true}
                          />
                          <div className="invalid-feedback">
                            Please provide a valid city.
                          </div>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom03"
                          >
                            Surgery Address
                          </label>
                          <Input
                            type="text"
                            value={this.state.surgeryAddress}
                            readOnly={true}
                          />
                          <div className="invalid-feedback">
                            Please provide a valid city.
                          </div>
                        </Col>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Medications</h3>
                </CardHeader>
                <ToolkitProvider
                  keyField="id"
                  data={this.state.medications}
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "recurrence",
                      text: "Recurrence",
                      sort: true
                    }
                  ]}
                  search
                >
                  {props => (
                    <div className="py-4 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label>
                          Search:
                          <SearchBar
                            className="form-control-sm"
                            placeholder=""
                            {...props.searchProps}
                          />
                        </label>
                      </div>
                      {this.state.loading ? <Spinner style={{ marginLeft: 15 }} color="primary" /> :
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                          loading={true}
                          hover={true}
                        />}
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </div>
            <div className="col">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Order Notes</h3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate>
                      <div className="form-row">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            {this.state.deliveryNotes}
                          </label>
                        </div>
                    </Form>
                  </CardBody>
                </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default PatientOrderDetails;
