/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

import { remediApi } from '../../../remediApi';

import DateTime from "react-datetime"

import moment from 'moment'

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  Col,
  UncontrolledTooltip
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  )
});

const { ExportCSVButton } = CSVExport;

class SupplierExport extends React.Component {
  state = {
    alert: null,
    data: [],
    loading: true,
    date: moment()
  };

  async componentDidMount() {
    await this._populateOrders();
  }

  _populateOrders = async () => {
    let report = await remediApi.getSupplierOrder(this.state.date.format('YYYY-MM-DD'));
    if(report) {
      this.setState({data: report, loading: false});
    }
  }

  render() {
    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Supplier Order" parentName="Data Export" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col data-export">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Supplier Order</h3>
                  <p className="text-sm mb-0">
                    Daily drug requisition report, select download to export to CSV format.
                  </p>
                </CardHeader>
                <ToolkitProvider
                  keyField="pipCode"
                  style={{minHeight:500}}
                  data={this.state.data}
                  exportCSV={ {
                    fileName: `requistion_${this.state.date.format('DD_MM_YYYY')}.csv`,
                    separator: ',',
                    ignoreHeader: true,
                    noAutoBOM: false
                  } }
                  columns={[
                    {
                      dataField: "pipCode",
                      text: "pipCode",
                      sort: true,
                    },
                    {
                      dataField: "description",
                      text: "Description",
                      sort: true
                    },
                    {
                      dataField: "count",
                      text: "Count",
                      sort: true
                    }
                  ]}
                  search
                >
                  {props => (
                    <div className="py-4 table-responsive">
                      {this.state.loading ? <div/> : <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                        style={{width: '100%'}}
                      >
                          Search:
                          <DateTime 
                            className='supplier-order-modal-search-date'
                            inputProps={{
                                className: 'form-control supplier-order-modal-search-date'
                            }}
                            value={this.state.date} 
                            timeFormat={false} 
                            dateFormat="DD/MM/YYYY" 
                            closeOnSelect={true} 
                            onChange={async (date) => { 
                                this.setState({date:date, loading: true}, async () => {
                                    await this._populateOrders()
                                });
                            }}/>
                            <ExportCSVButton className="btn btn-info btn-sm" style={{float:'right', backgroundColor: '#39DEAE', borderColor: '#39DEAE'}} { ...props.csvProps }>Download</ExportCSVButton>
                      </div>}
                      {this.state.loading ? <Spinner style={{marginLeft: 15}} color="primary" /> :
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        loading={true}
                        hover={true}
                        defaultSorted={[{
                          dataField: 'name',
                          order: 'asc'
                        }]}
                      />}
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default SupplierExport;
