/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

import { remediApi } from '../../../remediApi';

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  Col,
  UncontrolledTooltip
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  )
});

const { SearchBar } = Search;

class Patients extends React.Component {
  state = {
    alert: null,
    data: [],
    traysEnabled: false,
    loading: true
  };
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  copyToClipboardAsTable = el => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Good job!"
          onConfirm={() => this.setState({ alert: null })}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Copied to clipboard!
        </ReactBSAlert>
      )
    });
  };

  async componentDidMount() {
    let traysEnabled = false;
    if('pharmacy' in localStorage) {
      var pharmacy = JSON.parse(localStorage.getItem('pharmacy'));
      if(pharmacy.services.includes('trays')) { traysEnabled = true; }
    }

    let patients = await remediApi.getPatients();

    if (patients) {
      this.setState({ data: patients, traysEnabled: traysEnabled, loading: false });
    } else {
      this.setState({ loading: false })
    }
  }

  render() {
    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Patients" parentName="Remedi" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <h3 className="mb-0" style={{ float: 'left', marginTop: 4 }}>Patients
                    <p className="text-sm mb-0">
                      Remedi platform users that have registered via the Remedi Repeats mobile application.
                    </p></h3>
                  {this.state.traysEnabled && <span style={{ float: 'right' }}>
                    <Button className="btn btn-info btn-sm process-btn" style={{ marginLeft: 15 }} onClick={() => {
                      this.props.history.push('/admin/newPatient')
                    }}>
                      <span className="btn-inner--icon"><i className="fas fa-plus"></i> Add Patient</span>
                    </Button>
                  </span>}
                </CardHeader>
                <ToolkitProvider
                  keyField="_id"
                  data={this.state.data}
                  columns={[
                    {
                      dataField: 'select',
                      isDummyField: true,
                      text: 'Select',
                      formatter: (cellContent, row) => {
                        return (
                          <Button className="btn btn-default btn-sm btn-icon-only" onClick={(e) => {
                            //console.log(row._id);
                            this.props.history.push('/admin/patient?id=' + row.MojoId)
                          }}>
                            <span className="btn-inner--icon"><i className="fas fa-search"></i></span>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'name',
                      isDummyField: true,
                      text: 'Name',
                      sort: true,
                      formatter: (cellContent, row) => {
                        return (
                          <span>{row.firstname} {row.lastname}</span>
                        );
                      },
                      sortValue: (cellContent, row) => {
                        return (row.firstname + row.lastname);
                      }
                    },
                    {
                      dataField: "email",
                      text: "Email",
                      sort: true
                    },
                    {
                      dataField: "dob",
                      text: "Date of Birth",
                      sort: true
                    },
                    {
                      dataField: "phoneNumber",
                      text: "Phone",
                      sort: true
                    },
                    {
                      dataField: 'hasMedication',
                      isDummyField: true,
                      text: 'Medication',
                      sort: true,
                      formatter: (cellContent, row) => {
                        return (
                          <span>{row.medications != null ? <i className="fas fa-check" style={{ fontSize: 20, color: '#39DEAE' }} /> : ''}</span>
                        );
                      },
                      sortValue: (cellContent, row) => {
                        return (row.firstname + row.lastname);
                      }
                    },
                    {
                      dataField: "MojoEntryDate",
                      text: "Registration Date",
                      sort: true,
                      type: "date"
                    },
                    {
                      dataField: "lastOrderDate",
                      text: "Last Order Date",
                      sort: true,
                      formatter: (cellContent, row) => {
                        return (
                          <span>{row.lastOrderDate ? row.lastOrderDate : ''}</span>
                        );
                      },
                    },
                  ]}
                  search
                >
                  {props => (
                    <div className="py-4 table-responsive">
                      {this.state.loading ? <div /> : <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label>
                          Search:
                          <SearchBar
                            className="form-control-sm"
                            placeholder=""
                            {...props.searchProps}
                          />
                        </label>
                      </div>}
                      {this.state.loading ? <Spinner style={{ marginLeft: 15 }} color="primary" /> :
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                          loading={true}
                          hover={true}
                          defaultSorted={[{
                            dataField: 'name',
                            order: 'asc'
                          }]}
                        />}
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Patients;
