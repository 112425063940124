/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowsRotate,
  faArrowLeft,
  faArrowRight,
  faThumbsDown
} from '@fortawesome/free-solid-svg-icons'
import Board from 'react-trello'
import Select2 from "react-select2-wrapper";
import { v4 as uuidv4 } from 'uuid';
import { remediApi } from '../../../remediApi';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Label from "reactstrap/lib/Label";
import { ThirdPartyDraggable } from "@fullcalendar/interaction";

class Trays extends React.Component {
  state = {
    alert: null,
    loading: true,
    weekDates: [],
    weekStart: null,
    weekEnd: null,
    dateLabel: "",
    selectedFilter: "7",
    trayDetailsModal: false,
    selectedCard: null,
    note: null,
    multiWeekValue: 2,
    multiWeekNote: null,
    scheduleDateLookup: [],
    trayPatients: [],
    cards: [],
    data: {
      lanes: [
        {
          style: { backgroundColor: '#ABDEE6', fontSize: '1rem' },
          id: 'new',
          title: 'New',
          cards: []
        },
        {
          style: { backgroundColor: '#F6EAB2' },
          id: 'ordered',
          title: 'Ordered',
          cards: []
        },
        {
          style: { backgroundColor: '#D9D7F1' },
          id: 'ccRequired',
          title: 'CC req',
          cards: []
        },
        {
          style: { backgroundColor: '#B7CADB' },
          id: 'ready',
          title: 'Ready',
          cards: []
        },
        {
          style: { backgroundColor: '#FFC8A2' },
          id: 'dispensed',
          title: 'Dispensed',
          cards: []
        },
        {
          style: { backgroundColor: '#CCE2CB' },
          id: 'checked',
          title: 'Checked',
          cards: []
        },
        {
          style: { backgroundColor: '#EEE' },
          id: 'completed',
          title: 'Completed',
          cardDraggable: false,
          cards: []
        }
      ]
    }
  };

  _nextStatus(status) {
    switch (status) {
      case 'new': {
        return 'ordered';
      }
      case 'ordered': {
        return 'ccRequired';
      }
      case 'ccRequired': {
        return 'ready';
      }
      case 'ready': {
        return 'dispensed';
      }
      case 'dispensed': {
        return 'checked';
      }
      case 'checked': {
        return 'completed'
      }
    }
    return '';
  }

  async componentDidMount() {
    await this._load();
  }

  async _load(date = new Date(), selected) {
    this.setState({ loading: true });
    selected = selected ?? this.state.selectedFilter;

    let trays = null;
    this._clearTrays();
    let weekDates = this._updateWeekDates(date);
    this._updateDayPickerDates(selected, weekDates);

    if (selected === '7') {
      trays = await remediApi.getWeeklyTrays(moment(weekDates[0]).format('YYYY-MM-DD'));
      this._drawTrays(trays, selected);
    }
    else {
      trays = await remediApi.getDailyTrays(moment(date).format('YYYY-MM-DD'));
      this._drawTrays(trays, selected);
    }

    if (trays) {
      this.setState({ trays: trays, loading: false });
    } else {
      this.setState({ loading: false })
    }
  }

  _updateWeekDates(date) {
    var weekDates = this._getWeekDates(this._getStartOfWeek(date))
    this.setState({ weekDates: weekDates });
    return weekDates;
  }

  _clearTrays() {
    if (this.state.data) {
      var lanes = this.state.data.lanes;
      for (var index in lanes) {
        var column = lanes[index];
        column.cards = [];
      }
      this.setState({ cards: [] });
    }
  }

  _getWeekDates(weekStart) {
    weekStart.setHours(10);
    weekStart.setMinutes(0);
    weekStart.setSeconds(0);
    var dates = [];
    var dateBuffer = new Date(weekStart);
    for (let i = 0; i < 7; i++) {
      dates.push(new Date(dateBuffer));
      dateBuffer.setDate(dateBuffer.getDate() + 1);
    }
    return dates;
  }

  _getStartOfWeek(date) {
    var day = date.getDay() || 7;
    if (day !== 1) {
      return moment(date).add('d', -(day - 1)).toDate()
    }
    return date;
  }

  _updateDayPickerDates(selected, weekDates) {
    if (selected === '7') {
      this.setState({ dateLabel: this._toShortDate(weekDates[0]) + " - " + this._toShortDate(weekDates[5]) })
    } else {
      this.setState({ dateLabel: this._toShortDate(weekDates[selected - 1]) })
    }
  }

  _drawTrays(response, selected) {
    if (response) {

      var scheduleDateLookup = []
      var trayInstances = []
      var trayPatients = []

      if (selected == "7") {
        for (var scheduledDate in response) {
          var trayPatientList = response[scheduledDate];
          for (var index in trayPatientList) {
            var trayInstance = null;
            var trayPatient = trayPatientList[index]
            scheduleDateLookup[trayPatient.mojoId] = scheduledDate
            trayPatients.push(trayPatient);

            if (trayPatient.trays != null && trayPatient.trays.length > 0) {
              let tray = trayPatient.trays.find(t => t.scheduledDate === scheduledDate)
              let trayInstance = { ...tray }

              if (tray != null && tray != undefined) {
                trayInstance.frequency = trayPatient.frequency;
                trayInstance.patientName = trayPatient.patientName;
                trayInstance.collectionSite = trayPatient.collectionSite;
                trayInstance.patientId = trayPatient.patientId;
                trayInstance.pharmacistCheck = trayPatient.pharmacistCheck;
                trayInstance.notRequired = trayPatient.notRequired;
                trayInstances.push(trayInstance);
              } else {
                trayInstance = {
                  status: 'new',
                  scheduledDate: scheduledDate,
                  patientName: trayPatient.patientName,
                  collectionSite: trayPatient.collectionSite,
                  patientId: trayPatient.patientId,
                  frequency: trayPatient.frequency,
                  pharmacistCheck: trayPatient.pharmacistCheck,
                  notRequired: trayPatient.notRequired
                }
                trayInstances.push(trayInstance);
              }
            } else if (trayPatient.startDate <= scheduledDate) {
              trayInstance = {
                status: 'new',
                scheduledDate: scheduledDate,
                patientName: trayPatient.patientName,
                collectionSite: trayPatient.collectionSite,
                patientId: trayPatient.patientId,
                frequency: trayPatient.frequency,
                pharmacistCheck: trayPatient.pharmacistCheck,
                notRequired: trayPatient.notRequired
              }

              trayInstances.push(trayInstance);
            }
          }
        }
      } else {
        var dateBeingViewed = this.state.weekDates[selected - 1];
        for (var index in response) {
          var trayInstance = null;
          var tp = response[index];
          trayPatients.push(tp);
          if (tp.trays != null && tp.trays.length > 0) {
            trayInstance = tp.trays.find(t => t.scheduledDate === moment(dateBeingViewed).format('YYYY-MM-DD'))
          }

          if (!trayInstance) {
            trayInstance = {
              status: 'new',
              scheduledDate: moment(dateBeingViewed).format('YYYY-MM-DD'),
              patientName: tp.patientName,
              collectionSite: tp.collectionSite,
              patientId: tp.patientId,
              frequency: tp.frequency,
              pharmacistCheck: tp.pharmacistCheck,
              notRequired: tp.notRequired
            }
          }

          trayInstance.frequency = tp.frequency;
          trayInstance.patientName = tp.patientName;
          trayInstance.collectionSite = tp.collectionSite;
          trayInstance.patientId = tp.patientId
          trayInstance.pharmacistCheck = tp.pharmacistCheck;
          trayInstance.notRequired = tp.notRequired;
          trayInstances.push(trayInstance);
        }
      }

      var lanes = this.state.data.lanes;
      var cards = [];
      for (var i in lanes) {
        var column = lanes[i];
        var columnTrays = trayInstances.filter(t => t.status === column.id);
        for (var j in columnTrays) {
          var columnTray = columnTrays[j];
          console.log(columnTray)
          var card = {
            id: uuidv4(),
            title: columnTray.patientName,
            description: this._toShortDate(columnTray.scheduledDate),
            label: "",
            laneId: column.id,
            metadata: {
              scheduledDate: columnTray.scheduledDate,
              patientId: columnTray.patientId,
              tray: columnTray
            },
            tags: [{
              title: columnTray.frequency,
              style: columnTray.frequency == 'Weekly' ? { color: '#000', backgroundColor: '#2dce89' } : { color: '#fff', backgroundColor: '#256DB1' }
            }
            ]
          }

          if (columnTray.pharmacistCheck) card.tags.push({ title: 'POC', style: { color: '#000', backgroundColor: '#ffc200' } });
          if (columnTray.notRequired) card.tags.push({ title: 'Not Required', style: { color: '#fff', backgroundColor: '#b53737' } });
          card.tags.push({ title: columnTray.collectionSite, style: { color: '#fff', backgroundColor: '#A04BAD' } });
          if (columnTray.notes != null && columnTray.notes.length > 0) card.tags.push({ title: `Notes (${columnTray.notes.length})`, style: { color: '#000', backgroundColor: 'deepskyblue' } });
          column.cards.push(card);
          cards.push(card);
        }
      }

      this.setState({ trayPatients: trayPatients, scheduleDateLookup: scheduleDateLookup, cards: cards });

      // Sort the trays by firstname

      //reload

      //loading status false

      if (this.state.trayPatients.length == 0) {
        // force the UICV to redraw so it shows no trays are available for the given request
        //reload
        //self.setLoadingStatus(message: "No tray patients available", isLoading: true)
      }

    } else {
      // An error happened with the request..
      //setLoadingStatus(message: "Unable to load trays", isLoading: true)
    }
  }

  _toShortDate(date) {
    return moment(date).format('DD/MM/YYYY');
  }

  async _updateTray(metadata, status) {
    var trayPatients = this.state.trayPatients;
    var trayPatient = trayPatients.find(tp => tp.patientId === metadata.patientId);

    var card = this.state.cards.find(card => card.metadata.patientId === metadata.patientId);
    var tag = {
      title: `Saving - ${status}`,
      style: { color: '#fff', backgroundColor: 'green' }
    };

    card.tags.unshift(tag)
    this.setState({ data: this.state.data });

    if (trayPatient.trays === null) {
      trayPatient.trays = [];
      var tray = {
        entryDate: moment().format('YYYY-MM-DD'),
        scheduledDate: metadata.scheduledDate,
        status: status
      };
      trayPatient.trays.push(tray);
    } else {
      var tray = trayPatient.trays.find(tray => tray.scheduledDate === metadata.scheduledDate);
      if (tray) {
        tray.status = status;
      } else {
        var tray = {
          entryDate: moment().format('YYYY-MM-DD'),
          scheduledDate: metadata.scheduledDate,
          status: status
        };
        trayPatient.trays.push(tray);
      }
    }

    var result = await remediApi.updateTray(trayPatient);
    console.log(result)

    if (result && typeof result === 'string') {
      if (result.startsWith('409')) {
        this._warningAlert();
      } else {
        if (this._isJson(result)) {
          var updatedTrayPatient = JSON.parse(result);
          trayPatient.mojoVersion = updatedTrayPatient.MojoVersion;
          card.laneId = status;
          this.setState({ trayPatients: trayPatients });
        }
      }
    }
    card.tags.shift(tag);
    this.setState({ data: this.state.data });
  }

  async _addNote() {
    if (this.state.note != null && this.state.note.length > 0) {
      let patientId = this.state.selectedCard.patient.patientId;
      var trayPatients = this.state.trayPatients;
      var trayPatient = this.state.trayPatients.find(tp => tp.patientId === patientId);
      let tray = trayPatient.trays.find(t => t.scheduledDate === this.state.selectedCard.card.metadata.scheduledDate)

      if (tray.notes === null || !tray.notes) {
        tray.notes = [];
      }

      tray.notes.push({
        content: this.state.note,
        author: localStorage.getItem('email'),
        entryDate: moment(new Date()).format('YYYY-MM-DD')
      });

      var result = await remediApi.updateTray(trayPatient);
      console.log(result)

      if (result && typeof result === 'string') {
        if (result.startsWith('409')) {
          this._warningAlert();
        } else {
          if (this._isJson(result)) {
            var updatedTrayPatient = JSON.parse(result);
            trayPatient.mojoVersion = updatedTrayPatient.MojoVersion;
            this.setState({ trayPatients: trayPatients });

            this.setState({
              trayDetailsModal: false,
              alert: (
                <ReactBSAlert
                  success
                  style={{ display: "block" }}
                  title="Success"
                  onConfirm={async () => {
                    await this._hideAlert()
                    this.setState({ note: null })
                    await this._refresh();
                  }}
                  confirmBtnBsStyle="success"
                  confirmBtnText="Ok"
                  btnSize=""
                >
                  Note added successfully.
                </ReactBSAlert>
              )
            });
          }
        }
      } else {
        this.setState({
          alert: (
            <ReactBSAlert
              warning
              style={{ display: "block" }}
              title="Warning"
              onConfirm={this._hideAlert}
              confirmBtnBsStyle="warning"
              confirmBtnText="Ok"
              btnSize=""
            >
              Failed to add note, please try again.
            </ReactBSAlert>
          )
        });
      }
    }
  }

  async _updateMultiTray() {
    let card = this.state.selectedCard.card;
    let patient = this.state.selectedCard.patient;

    let tray = {
      patientId: patient.patientId,
      requestedDate: card.metadata.scheduledDate,
      weeks: this.state.multiWeekValue,
      // note: this.state.multiWeekNote && this.state.multiWeekNote.length > 0 ? {
      //   content: this.state.multiWeekNote,
      // } : null,
      requestedStatus: this._nextStatus(card.laneId),
    }

    if(this.state.multiWeekNote && this.state.multiWeekNote.length > 0) {
      tray.note = {
        content: this.state.multiWeekNote
      }
    }

    var result = await remediApi.updateMultiTray(tray);
    let resultObject = JSON.parse(result);

    console.log(resultObject);

    if (resultObject.result) {
      this.setState({
        trayDetailsModal: false,
        alert: (
          <ReactBSAlert
            success
            style={{ display: "block" }}
            title="Success"
            onConfirm={async () => {
              await this._hideAlert()
              this.setState({ multiWeekValue: 2, multiWeekNote: null })
              await this._refresh();
            }}
            confirmBtnBsStyle="success"
            confirmBtnText="Ok"
            btnSize=""
          >
            Multi tray stage change successful.
          </ReactBSAlert>
        )
      });
    } else {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block" }}
            title="Warning"
            onConfirm={this._hideAlert}
            confirmBtnBsStyle="warning"
            confirmBtnText="Ok"
            btnSize=""
          >
            {resultObject.resultString}
          </ReactBSAlert>
        )
      });
    }
  }

  _isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  _updateSelected = async ({ target: { value } }) => {
    if (value === '7') {
      await this._load(this.state.weekDates[0], value);
    } else {
      await this._load(this.state.weekDates[value - 1], value);
    }

    this.setState({ selectedFilter: value });
  }

  _warningAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          info
          style={{ display: "block" }}
          title="Please refresh!"
          onConfirm={this._hideAlert}
          //confirmBtnBsStyle="info"
          confirmBtnText="Refresh"
          btnSize=""
        >
          This patient requires a refesh before further updates.
        </ReactBSAlert>
      )
    });
  };

  _hideAlert = async () => {
    await this._refresh();
    this.setState({
      alert: null
    });
  };

  _cardClicked = (cardId) => {
    const card = this.state.cards.find(c => c.id === cardId);
    const patient = this.state.trayPatients.find(p => p.patientId === card.metadata.patientId);
    this.setState({ trayDetailsModal: true, selectedCard: { card, patient } });
  }

  _refresh = async (e) => {
    if (this.state.selectedFilter === '7') {
      await this._load(this.state.weekDates[0], this.state.selectedFilter);
    } else {
      await this._load(this.state.weekDates[this.state.selectedFilter - 1], this.state.selectedFilter);
    }
  }

  render() {
    let status = this.state.selectedCard ? this.state.selectedCard.card.laneId : '';
    let nextStatus = this.state.selectedCard ? this._nextStatus(this.state.selectedCard.card.laneId) : '';
    let noteCount = this.state.selectedCard && this.state.selectedCard.card.metadata.tray.notes != null ? this.state.selectedCard.card.metadata.tray.notes.length : 0;

    let data = [{
      attribute: 'Name',
      value: this.state.selectedCard ? this.state.selectedCard.patient.patientName : ''
    }, {
      attribute: 'Status',
      value: this.state.selectedCard ? this.state.selectedCard.card.laneId : ''
    }, {
      attribute: 'Start Date',
      value: this.state.selectedCard ? this._toShortDate(this.state.selectedCard.patient.startDate) : ''
    }, {
      attribute: 'Scheduled Date',
      value: this.state.selectedCard ? this.state.selectedCard.card.description : ''
    }, {
      attribute: 'Frequency',
      value: this.state.selectedCard ? this.state.selectedCard.patient.frequency : ''
    }];

    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Trays" parentName="Remedi" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <h3 className="mb-0" style={{ float: 'left', marginTop: 4 }}>Trays
                    <p className="text-sm mb-0">
                      Trays for Remedi patients with prescription trays enabled.
                    </p>
                  </h3>
                  <span className="tray-date-range" style={{ float: 'right' }}>
                    {this.state.loading ?
                      <Spinner color="primary" style={{ width: "1rem", height: "1rem", marginRight: "5px" }} /> :
                      <span>
                        <Button className="btn btn-default btn-sm btn-icon-only" onClick={async (e) => {
                          if (this.state.selectedFilter === "7") {
                            var date = this.state.weekDates[0];
                            date = moment(date).add(-1, 'w');
                            await this._load(date.toDate(), this.state.selectedFilter)
                          } else {
                            var date = this.state.weekDates[this.state.selectedFilter - 1];
                            date = moment(date).add(-1, 'w');
                            await this._load(date.toDate(), this.state.selectedFilter);
                          }
                        }}>
                          <span className="btn-inner--icon"><FontAwesomeIcon icon={faArrowLeft} /></span>
                        </Button>
                        <Label style={{ marginRight: "0.5rem" }}>{this.state.dateLabel}</Label>
                        <Button className="btn btn-default btn-sm btn-icon-only" onClick={async (e) => {
                          if (this.state.selectedFilter === "7") {
                            var date = this.state.weekDates[0];
                            date = moment(date).add(1, 'w');
                            await this._load(date.toDate(), this.state.selectedFilter)
                          } else {
                            var date = this.state.weekDates[this.state.selectedFilter - 1];
                            date = moment(date).add(1, 'w');
                            await this._load(date.toDate(), this.state.selectedFilter);
                          }
                        }}>
                          <span className="btn-inner--icon"><FontAwesomeIcon icon={faArrowRight} /></span>
                        </Button>
                      </span>}
                    <Select2
                      required
                      className="tray-date-range-select form-control select-invalid"
                      defaultValue={this.state.selectedFilter}
                      options={{
                        placeholder: "Select"
                      }}
                      readOnly={!this.state.editable}
                      data-minimum-results-for-search="Infinity"
                      data={[
                        { id: "7", text: "All Week" },
                        { id: "1", text: "Monday" },
                        { id: "2", text: "Tuesday" },
                        { id: "3", text: "Wednesday" },
                        { id: "4", text: "Thursday" },
                        { id: "5", text: "Friday" },
                        { id: "6", text: "Saturday" },
                      ]}
                      onSelect={this._updateSelected}
                    />
                    <Button className="btn btn-info btn-sm btn-icon-only" onClick={this._refresh}>
                      <span className="btn-inner--icon"><FontAwesomeIcon icon={faArrowsRotate} /></span>
                    </Button>
                  </span>
                </CardHeader>
                {this.state.loading ?
                  <Spinner color="primary" style={{ margin: 15 }} /> : <Board onCardClick={this._cardClicked} data={this.state.data} hideCardDeleteIcon={true} style={{ backgroundColor: 'white', fontSize: 16 }}
                    laneStyle={{ color: '#525f7f' }} cardStyle={{ fontSize: 16 }} handleDragEnd={(cardId, sourceLaneId, targetLaneId, position, cardDetails) => {

                      if ((sourceLaneId != 'new' && targetLaneId === 'new' || sourceLaneId === targetLaneId)) return false;

                      switch (sourceLaneId) {
                        case 'new':
                          {
                            if (targetLaneId === 'ordered') {
                              this._updateTray(cardDetails.metadata, targetLaneId);
                              return true;
                            }
                            break;
                          }
                        case 'ordered':
                          {
                            if (targetLaneId === 'ccRequired') {
                              this._updateTray(cardDetails.metadata, targetLaneId);
                              return true;
                            }
                            break;
                          }
                        case 'ccRequired':
                          {
                            if (targetLaneId === 'ready') {
                              this._updateTray(cardDetails.metadata, targetLaneId);
                              return true;
                            }
                            break;
                          }
                        case 'ready':
                          {
                            if (targetLaneId === 'dispensed') {
                              this._updateTray(cardDetails.metadata, targetLaneId);
                              return true;
                            }
                            break;
                          }
                        case 'dispensed':
                          {
                            if (targetLaneId === 'checked') {
                              this._updateTray(cardDetails.metadata, targetLaneId);
                              return true;
                            }
                            break;
                          }
                        case 'checked':
                          {
                            if (targetLaneId === 'completed') {
                              this._updateTray(cardDetails.metadata, targetLaneId);
                              return true;
                            }
                            break;
                          }
                      }

                      return false;
                    }} />}
              </Card>
            </div>
          </Row>
        </Container>
        <Modal size="lg" isOpen={this.state.trayDetailsModal} toggle={() => { this.setState({ note: null, multiWeekNote: null, trayDetailsModal: false }) }}>
          <ModalHeader toggle={() => { this.setState({ note: null, multiWeekNote: null, trayDetailsModal: false }) }}>Tray Details</ModalHeader>
          <ModalBody className="tray-details-table">
            <ToolkitProvider
              keyField="attribute"
              data={data}
              columns={[
                {
                  dataField: "attribute",
                  text: "Attribute",
                },
                {
                  dataField: "value",
                  text: "Value",
                },
              ]}
              search
            >
              {props => (
                <div className="py-4 table-responsive">
                  <BootstrapTable className="tray-details-table"
                    {...props.baseProps}
                    bootstrap4={true}
                    bordered={false}
                    loading={true}
                    hover={true}
                  />
                </div>
              )}
            </ToolkitProvider>
            <Tabs selected={status === 'new' ? 2 : 0} onSelect={(index, label) => console.log(label + ' selected')}>
              <Tab disabled={status === 'new'} label={noteCount > 0 ? `Notes (${noteCount})` : 'Notes'}>
                <ToolkitProvider
                  keyField="attribute"
                  data={this.state.selectedCard && this.state.selectedCard.card.metadata.tray.notes ? this.state.selectedCard.card.metadata.tray.notes : []}
                  columns={[
                    {
                      dataField: "content",
                      text: "Content",
                    },
                    {
                      dataField: "author",
                      text: "Author",
                    },
                    {
                      dataField: "entryDate",
                      text: "Entry Date",
                    },
                  ]}
                  search
                >
                  {props => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable className="tray-details-table"
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        loading={true}
                        hover={true}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Tab>
              <Tab disabled={status === 'new'} label={'New Note'}>
                <div className="form-row">
                  <Col className="mb-3" md="12">
                    <label
                      className="form-control-label"
                      htmlFor="validationCustom01"
                      style={{ marginTop: 25 }}
                    >
                      Note
                    </label>                        <Input
                      id="exampleFormControlTextarea3"
                      resize="none"
                      rows="3"
                      type="textarea"
                      value={this.state.note}
                      onChange={(e) => this.setState({ note: e.target.value })}
                    /></Col></div>
                <div className="form-row">
                  <Button color="btn btn-icon btn-default" onClick={async () => { this._addNote() }}>Save</Button>
                </div>
              </Tab>
              <Tab disabled={status === 'completed'} label={'Multi Tray'}>
                <div className="form-row">
                  <Col className="mb-3" md="12">
                    <label
                      className="form-control-label"
                      htmlFor="validationCustom01"
                      style={{ marginTop: 25 }}
                    >
                      Status Transition: <span style={{ color: 'goldenrod' }}>{status}</span> {'->'} <span style={{ color: '#39DEAE' }}>{nextStatus}</span>
                    </label>
                  </Col>
                </div>
                <div className="form-row">
                  <Col className="mb-3" md="4">
                    <label
                      className="form-control-label"
                      htmlFor="validationCustom01"
                    >
                      Weeks
                    </label>
                    <Select2
                      className="form-control"
                      defaultValue={this.state.multiWeekValue}
                      data-minimum-results-for-search="Infinity"
                      data={[
                        { id: 2, text: "2 weeks" },
                        { id: 3, text: "3 weeks" },
                        { id: 4, text: "4 weeks" },
                        { id: 5, text: "5 weeks" },
                        { id: 6, text: "6 weeks" },
                        { id: 7, text: "7 weeks" },
                        { id: 8, text: "8 weeks" },
                      ]}
                      onChange={e => {
                        this.state.multiWeekValue = e.target.value
                      }}
                    />

                  </Col>
                </div>
                <div className="form-row">
                  <Col className="mb-3" md="12">
                    <label
                      className="form-control-label"
                      htmlFor="validationCustom01"
                    >
                      Note
                    </label>                        <Input
                      id="exampleFormControlTextarea3"
                      resize="none"
                      rows="3"
                      type="textarea"
                      value={this.state.multiWeekNote}
                      onChange={(e) => this.setState({ multiWeekNote: e.target.value })}
                    /></Col></div>
                <div className="form-row">
                  <Button color="btn btn-icon btn-default" onClick={() => { this._updateMultiTray() }}>Save</Button>
                </div>
              </Tab>
            </Tabs>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Trays;
