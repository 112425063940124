/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes

import paginationFactory from "react-bootstrap-table2-paginator";

import { remediApi } from '../../../remediApi';

import NotificationAlert from 'react-notification-alert';

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
          entries.
        </label>
        </div>
    )
});

const { SearchBar } = Search;

class Settings extends React.Component {
    state = {
        name: "",
        address: "",
        phone: "",
        leadTime: "",
        avatarImage: "",
        logoImage: "",
        avatarUrl: "",
        logoUrl: "",
        collectionSites: [],
        surgeries: [],
        newsfeed: [],
        id: '',
        version: '',
        loading: true,
        editable: false,
        leadTimeUpdate: false,
        surgeryModal: false,
        surgeriesMetadata: [],
        filteredSurgeriesMetadata: [],
        surgerySelected: false,
        selectedSurgeryRow: null,
        surgeryModalMode: '',
        registrationOpen : false,
        saving: false,
        registrationOpenUpdate: false
    };

    async componentDidMount() {
        await this._setPharmacyData();
    }

    async _setPharmacyData() {
        let pharmacy = {};
        if('pharmacy' in localStorage) {
            pharmacy = JSON.parse(localStorage.getItem('pharmacy'));
        }
        let surgeriesMetadata = await remediApi.getSurgeries();

        if (pharmacy) {
            this.setState({
                name: pharmacy.name,
                address: pharmacy.address,
                phone: pharmacy.phone,
                leadTime: pharmacy.leadTime,
                avatarImage: pharmacy.avatarImage,
                logoImage: pharmacy.logoImage,
                avatarUrl: pharmacy.avatarUrl,
                logoUrl: pharmacy.logoUrl,
                registrationOpen: pharmacy.registrationOpen,
                collectionSites: pharmacy.collectionSites,
                surgeries: pharmacy.surgeries ?? [],
                newsfeed: pharmacy.newsfeed ?? [],
                id: pharmacy.MojoId,
                version: pharmacy.MojoVersion,
                loading: false,
                leadTimeUpdate: false,
                surgeriesMetadata: surgeriesMetadata,
                registrationOpenUpdate: false
            });
        }
    }

    async _update() {
        var avatarInput = document.getElementById("avatar-input");
        var logoInput = document.getElementById("logo-input");

        let success = true;
        let avatarFile = avatarInput.files[0];
        let logoFile = logoInput.files[0];

        if (avatarFile) {
            success = await remediApi.updateAvatar(this.state.id, this.state.version, avatarFile);
            if(success) { this.setState(prev => ({version: prev.version + 1 }))}
        }

        if(logoFile) {
            success = await remediApi.updateLogo(this.state.id, this.state.version, logoFile);
            if(success) { this.setState(prev => ({version: prev.version + 1 }))}
        }

        var options = {};

        if (success) {
            if (this.state.leadTimeUpdate) {
                success = await remediApi.updateLeadTime(this.state.id, this.state.version, this.state.leadTime);

                if(!success) {
                    let options = {
                        place: 'br',
                        message: (
                            <div>
                                <div>
                                    Lead time update failed, please try again.
                                </div>
                            </div>
                        ),
                        type: "danger",
                        autoDismiss: 7
                    };
                    this.refs.notify.notificationAlert(options);

                    return;
                }
            }

            if(success) { this.setState(prev => ({version: prev.version + 1 }))}

            if(this.state.registrationOpenUpdate) {
                success = await remediApi.updateRegistrationOpen(this.state.id, this.state.version, this.state.registrationOpen);

                if(!success) {
                    let options = {
                        place: 'br',
                        message: (
                            <div>
                                <div>
                                    Available for registration update failed, please try again.
                                </div>
                            </div>
                        ),
                        type: "danger",
                        autoDismiss: 7
                    };
                    this.refs.notify.notificationAlert(options);
    
                    return;
                }
            }
        } else {
            let options = {
                place: 'br',
                message: (
                    <div>
                        <div>
                            Image update failed, please try again.
                  </div>
                    </div>
                ),
                type: "danger",
                autoDismiss: 7
            };
            this.refs.notify.notificationAlert(options);

            return;
        }

        options = {
            place: 'br',
            message: (
                <div>
                    <div>
                        Pharmacy details updated.
                </div>
                </div>
            ),
            type: "success",
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        }
        this.refs.notify.notificationAlert(options);
        this._setPharmacyData();
        this.setState({ editable: false });
        window.location.reload(true);
    }

    _closeSurgeryModal() {
        this.setState({ surgeryModalMode: '', surgeryModal: false, surgerySelected: false, selectedSurgeryRow: null })
    }

    _toShortname(address) {
        var matches = address.match(/\b(\w)/g);
        return matches.join('').toUpperCase().substring(0, 5);
    }

    render() {
        return (
            <>
                <SimpleHeader name="Pharmacy Settings" parentName="Remedi" />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <div className="card-wrapper">
                                <Card>
                                    <CardHeader>
                                        <h3 className="mb-0" style={{ float: 'left', marginTop: 4 }}>{this.state.loading ?
                                            <Spinner color="primary" /> :
                                            <span>{this.state.name}</span>}
                                        </h3>
                                        <span style={{ float: 'right' }}>
                                            {this.state.editable ? <Button className="btn btn-default btn-sm" style={{ marginLeft: 10 }} onClick={async (e) => {
                                                await this._update();
                                            }}>
                                                SAVE
                                            </Button> : <span />}
                                            <Button className="btn btn-info btn-sm" style={{ float: 'right' }} onClick={async (e) => {

                                                if (this.state.editable) {
                                                    await this._setPharmacyData();
                                                }

                                                this.setState(prevState => ({ editable: !prevState.editable, leadTimeUpdate: false, registrationOpenUpdate: false }));
                                            }}>
                                                {this.state.editable ? 'CANCEL' : 'EDIT'}
                                            </Button>
                                        </span>
                                    </CardHeader>
                                    <CardBody>
                                        <Form className="needs-validation" noValidate>
                                        <div className="form-row">
                                            <Col md="4">
                                                <div className="form-row">
                                                    <Col className="mb-3" md="11">
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="validationCustom01"
                                                        >
                                                            Name
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            value={this.state.name}
                                                            readOnly={true}
                                                        />
                                                        <div className="valid-feedback">Looks good!</div>
                                                    </Col>
                                                </div>
                                                <div className="form-row">
                                                    <Col className="mb-3" md="11">
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="validationCustom02"
                                                        >
                                                            Address
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            value={this.state.address}
                                                            readOnly={true}
                                                        />
                                                        <div className="valid-feedback">Looks good!</div>
                                                    </Col>
                                                </div>
                                                <div className="form-row">
                                                    <Col className="mb-3" md="11">
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="validationCustom01"
                                                        >
                                                            Phone Number
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            value={this.state.phone}
                                                            readOnly={true}
                                                            pattern="[0-9]*"
                                                        />
                                                        <div className="valid-feedback">Looks good!</div>
                                                    </Col>
                                                </div>
                                                <div className="form-row">
                                                    <Col className="mb-3" md="11">
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="validationCustom01"
                                                        >
                                                            Lead Time (Days)
                                                        </label>
                                                        <Input
                                                            type="number"
                                                            value={this.state.leadTime}
                                                            readOnly={!this.state.editable}
                                                            pattern="[0-9]*"
                                                            min='1'
                                                            max='10'
                                                            onKeyDown={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                            onChange={(e) => {
                                                                this.setState({ leadTime: e.target.value, leadTimeUpdate: true })
                                                            }}
                                                        />
                                                        <div className="valid-feedback">Looks good!</div>
                                                    </Col>
                                                </div>
                                                <div className="form-row">
                                                    <Col md="11">
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="validationCustom01"
                                                        >
                                                            Available for registration
                                                        </label>
                                                        <label
                                                            style={{fontSize:13}}
                                                            htmlFor="validationCustom01"
                                                        >
                                                            If disabled, your pharmacy will not appear in the pharmacy selection of the app registration page.
                                                        </label>
                                                        <div>
                                                            <label className="custom-toggle">
                                                                <input 
                                                                    type="checkbox" 
                                                                    disabled={!this.state.editable}
                                                                    checked={this.state.registrationOpen}
                                                                    onChange={(e) => {
                                                                        this.setState({ registrationOpen: e.target.checked, registrationOpenUpdate: true })
                                                                    }}
                                                                    />
                                                                <span className="custom-toggle-slider rounded-circle" />
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Col>
                                            <Col md="8">
                                                <div className="form-row">
                                                    <Col md="6">
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="validationCustom01"
                                                        >
                                                            Avatar
                                                        </label>
                                                        <div>
                                                            <img style={{ marginBottom: 10, width: 70, padding: 10, border: '1px solid rgba(0, 0, 0, 0.05)' }} ref={(node) => {
                                                                if (node) {
                                                                    node.style.setProperty("border-radius", "0", "important");
                                                                }
                                                            }}
                                                                alt="..."
                                                                src={this.state.avatarUrl ?? require("assets/img//logov2.png")}
                                                            />
                                                        </div>
                                                        {this.state.editable ? <div className="custom-file">
                                                            <Input
                                                                className="custom-file-input"
                                                                id="avatar-input"
                                                                lang="en"
                                                                type="file"
                                                                accept=".png"
                                                            />
                                                        </div> : <div />}
                                                    </Col>
                                                </div>
                                                <div className="form-row">
                                                    <Col md="8">
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="validationCustom01"
                                                        >
                                                            Logo for Remedi App
                                                        </label>
                                                        <label
                                                            style={{fontSize:13}}
                                                            htmlFor="validationCustom01"
                                                        >
                                                            iOS dark theme on the right, ensure the .png file has a transparent background for this to render correctly.
                                                        </label>
                                                    </Col>
                                                </div>
                                                <div className="form-row">
                                                    <Col md="3">
                                                       
                                                        <div>
                                                            <img style={{ marginBottom: 10, width: '100%', maxWidth: 200, padding: 10, border: '1px solid rgba(0, 0, 0, 0.05)' }} ref={(node) => {
                                                                if (node) {
                                                                    node.style.setProperty("border-radius", "0", "important");
                                                                }
                                                            }}
                                                                alt="..."
                                                                src={this.state.logoUrl ?? require("assets/img//logov2_tr.png")}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="3">
                                             
                                                        <div>
                                                            <img style={{ backgroundColor: '#1C1C1E', marginBottom: 10, width: '100%', maxWidth: 200, padding: 10, border: '1px solid rgba(0, 0, 0, 0.05)' }} ref={(node) => {
                                                                if (node) {
                                                                    node.style.setProperty("border-radius", "0", "important");
                                                                }
                                                            }}
                                                                alt="..."
                                                                src={this.state.logoUrl ?? require("assets/img//logov2_tr.png")}
                                                            />
                                                        </div>
                                                    </Col>
                                                </div>
                                                <div className="form-row">
                                                    <Col>
                                                        {this.state.editable ? <div className="custom-file">
                                                            <Input
                                                                className="custom-file-input"
                                                                id="logo-input"
                                                                lang="en"
                                                                type="file"
                                                                accept=".png"
                                                            />
                                                        </div> : <div />}</Col>
                                                </div>
                                            </Col>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0" style={{ float: 'left', marginTop: 4 }}>Surgeries</h3>
                                    <span style={{ float: 'right' }}>
                                        <Button className="btn btn-info btn-sm" style={{ float: 'right' }} onClick={async (e) => {
                                            let filter = this.state.surgeriesMetadata.filter(surgery => !this.state.surgeries.some(s => s._id === surgery._id));
                                            this.setState({ surgeryModalMode: 'add', surgeryModal: true, filteredSurgeriesMetadata: filter });
                                        }}>
                                            ADD
                                        </Button>
                                    </span>
                                </CardHeader>
                                <ToolkitProvider
                                    keyField="_id"
                                    data={this.state.surgeries}
                                    columns={[
                                        {
                                            dataField: 'select',
                                            isDummyField: true,
                                            text: 'Actions',
                                            editable: false,
                                            formatter: (cellContent, row) => {
                                                return (
                                                    <>
                                                        <Button className="btn btn-default btn-sm btn-icon-only" onClick={(e) => {
                                                            this.setState({ surgerySelected: true, surgeryModalMode: 'edit', surgeryModal: true, selectedSurgeryRow: Object.assign({}, row) });
                                                        }}>
                                                            <span className="btn-inner--icon"><i className="fas fa-edit"></i></span>
                                                        </Button>

                                                        {/* //TODO Ability to delete needs to be considered - we need to implement a data consistency service to resolve patients who have the deleted surgery set...
                                                  <Button className="btn btn-default btn-sm btn-icon-only" onClick={() => { 
                                                    }}>
                                                    <span className="btn-inner--ion"><i className="fas fa-trash-alt"></i></span>
                                                  </Button> */}
                                                    </>
                                                );
                                            }
                                        },
                                        {
                                            dataField: "practiceNumber",
                                            text: "Practice No",
                                            sort: true
                                        },
                                        {
                                            dataField: "name",
                                            text: "Name",
                                            sort: true
                                        },
                                        {
                                            dataField: "address",
                                            text: "Address",
                                            sort: true
                                        },
                                        {
                                            dataField: "postcode",
                                            text: "Post Code",
                                            sort: true
                                        },
                                        {
                                            dataField: "shortname",
                                            text: "Short Name",
                                            sort: true
                                        },
                                        {
                                            dataField: "leadTime",
                                            text: "Lead Time (Days)",
                                            sort: true
                                        },
                                        {
                                            dataField: "scriptLength",
                                            text: "Script Length (Months)",
                                            sort: true
                                        }
                                    ]}
                                    search
                                >
                                    {props => (
                                        <div className="py-4 table-responsive">
                                            <div
                                                id="datatable-basic_filter"
                                                className="dataTables_filter px-4 pb-1"
                                            >
                                                <label>
                                                    Search:
                                                    <SearchBar
                                                        className="form-control-sm"
                                                        placeholder=""
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                            </div>
                                            {this.state.loading ? <Spinner style={{ marginLeft: 15 }} color="primary" /> :
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    bootstrap4={true}
                                                    pagination={pagination}
                                                    bordered={false}
                                                    loading={true}
                                                    hover={true}
                                                />}
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Card>
                        </div>
                    </Row>
                </Container>
                <div style={{ zIndex: 1051, position: 'fixed' }}>
                    <NotificationAlert ref="notify" />
                </div>
                <Modal size="sm" isOpen={this.state.saving} toggle={() => { this.setState({ saving: !this.state.saving }) }}>
                    <ModalBody>
                    <Spinner size="sm" /> Please wait
                    </ModalBody>
                </Modal>
                <Modal size="xl" isOpen={this.state.surgeryModal} toggle={() => { this._closeSurgeryModal() }}>
                    <ModalHeader toggle={() => { this._closeSurgeryModal() }}>{this.state.surgeryModalMode === 'add' ? 'Add Surgery' : 'Edit Surgery'}</ModalHeader>
                    <ModalBody>
                        {!this.state.surgerySelected ? <ToolkitProvider
                            keyField="_id"
                            data={this.state.filteredSurgeriesMetadata}
                            columns={[
                                {
                                    dataField: "practiceNumber",
                                    text: "Practice No",
                                    sort: true
                                },
                                {
                                    dataField: "name",
                                    text: "Name",
                                    sort: true
                                },
                                {
                                    dataField: "address",
                                    text: "Address",
                                    sort: true
                                },
                                {
                                    dataField: "postcode",
                                    text: "Post Code",
                                    sort: true
                                }
                            ]}
                            search
                        >
                            {props => (
                                <div className="table-responsive">
                                    <div
                                        id="datatable-basic_filter"
                                        className="dataTables_filter px-4 pb-1"
                                    >
                                        <label>
                                            Search:
                                                    <SearchBar
                                                className="form-control-sm"
                                                placeholder=""
                                                {...props.searchProps}
                                            />
                                        </label>
                                    </div>
                                    {this.state.loading ? <Spinner style={{ marginLeft: 15 }} color="primary" /> :
                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                            loading={true}
                                            hover={true}
                                            rowEvents={{
                                                onClick: (e, row, rowIndex) => {
                                                    row.shortname = this._toShortname(row.address);
                                                    row.leadTime = 1;
                                                    row.scriptLength = 2;
                                                    this.setState({ selectedSurgeryRow: row, surgerySelected: true })
                                                }
                                            }}
                                        />}
                                </div>
                            )}
                        </ToolkitProvider>
                            : <span>
                                <label
                                    className="form-control-label"
                                    htmlFor="validationCustom03"
                                >
                                    Practice No
                                </label>
                                <Input
                                    type="text"
                                    value={this.state.selectedSurgeryRow.practiceNumber}
                                    readOnly={true}
                                />
                                <label
                                    className="form-control-label"
                                    htmlFor="validationCustom03"
                                >
                                    Name
                                </label>
                                <Input
                                    type="text"
                                    value={this.state.selectedSurgeryRow.name}
                                    readOnly={true}
                                />
                                <label
                                    className="form-control-label"
                                    htmlFor="validationCustom03"
                                >
                                    Address
                                </label>
                                <Input
                                    type="text"
                                    value={this.state.selectedSurgeryRow.address}
                                    readOnly={true}
                                />
                                <label
                                    className="form-control-label"
                                    htmlFor="validationCustom03"
                                >
                                    Post Code
                                </label>
                                <Input
                                    type="text"
                                    value={this.state.selectedSurgeryRow.postcode}
                                    readOnly={true}
                                />
                                <label
                                    className="form-control-label"
                                    htmlFor="validationCustom03"
                                >
                                    Short Name
                                </label>
                                <Input
                                    type="text"
                                    value={this.state.selectedSurgeryRow.shortname}
                                    onChange={(e) => {
                                        let surgery = this.state.selectedSurgeryRow;
                                        surgery.shortname = e.target.value;
                                        this.setState({ selectedSurgeryRow: surgery })
                                    }}
                                />
                                <label style={{ marginTop: 10 }}
                                    className="form-control-label"
                                    htmlFor="validationCustom03"
                                >
                                    Lead Time (Days):
                                </label>
                                <Input
                                    type="number"
                                    value={this.state.selectedSurgeryRow.leadTime}
                                    pattern="[0-9]*"
                                    min='1'
                                    max='10'
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    onChange={(e) => {
                                        let surgery = this.state.selectedSurgeryRow;
                                        surgery.leadTime = e.target.value;
                                        this.setState({ selectedSurgeryRow: surgery })
                                    }}
                                />
                                <label style={{ marginTop: 10 }}
                                    className="form-control-label"
                                    htmlFor="validationCustom03"
                                >
                                    Script Length (Months):
                                </label>
                                <Input
                                    type="number"
                                    value={this.state.selectedSurgeryRow.scriptLength}
                                    pattern="[0-9]*"
                                    min='1'
                                    max='10'
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    onChange={(e) => {
                                        let surgery = this.state.selectedSurgeryRow;
                                        surgery.scriptLength = e.target.value;
                                        this.setState({ selectedSurgeryRow: surgery })
                                    }}
                                />
                            </span>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="btn btn-icon btn-info" onClick={() => { this._closeSurgeryModal() }}>Close</Button>
                        {this.state.surgerySelected ? <Button color="btn btn-icon btn-default" onClick={async (e) => {
                            e.preventDefault();

                            this.setState({saving: true});

                            if(this.state.selectedSurgeryRow.shortname.length === 0)
                            {
                                this.state.selectedSurgeryRow.shortname = this._toShortname(this.state.selectedSurgeryRow.address);
                            }

                            let surgeries = Object.assign([], this.state.surgeries);

                            if(this.state.surgeryModalMode === 'add') {
                                surgeries.push(this.state.selectedSurgeryRow)
                            } else {
                                let index = surgeries.findIndex(s => s._id === this.state.selectedSurgeryRow._id);
                                surgeries[index] = this.state.selectedSurgeryRow;
                            }

                            let result = await remediApi.updateSurgeries(
                                this.state.id,
                                this.state.version,
                                surgeries);

                            var options = {};
                            let mode = this.state.surgeryModalMode;

                            if (result != null) {
                                await this._setPharmacyData();
                                this._closeSurgeryModal();
                                this.setState({saving: false});

                                options = {
                                    place: 'br',
                                    message: (
                                        <div>
                                            <div>
                                                {mode === 'add' ? 'Surgery added.' : 'Surgery updated.'}
                                            </div>
                                        </div>
                                    ),
                                    type: "success",
                                    icon: "now-ui-icons ui-1_bell-53",
                                    autoDismiss: 3
                                }
                                this.refs.notify.notificationAlert(options);
                            } else {
                                let options = {
                                    place: 'br',
                                    message: (
                                        <div>
                                            <div>
                                                {mode === 'add' ? 'Surgery add failed, please try again.' : 'Surgery update failed, please try again.'}

                                            </div>
                                        </div>
                                    ),
                                    type: "danger",
                                    autoDismiss: 7
                                };
                                this.refs.notify.notificationAlert(options);
                            }
                        }}>{this.state.surgeryModalMode === 'add' ? 'Add' : 'Save'}</Button> : <span />}
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default Settings;
