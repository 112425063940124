/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import Select2 from "react-select2-wrapper";
import NotificationAlert from 'react-notification-alert';

import { remediApi } from '../../../remediApi';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { isThisTypeNode } from "typescript";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  )
});

const { SearchBar } = Search;

class TrayState {
  // Create new instances of the same class as static attributes
  static NoTray = new TrayState("NoTray")
  static New = new TrayState("New")
  static Existing = new TrayState("Existing")

  constructor(name) {
    this.name = name
  }
}

class PatientDetails extends React.Component {
  state = {
    id: "",
    version: "",
    firstName: "",
    lastName: "",
    dob: "",
    phoneNumber: "",
    address: "",
    postcode: "",
    rxMethod: "",
    surgery: "",
    medications: [],
    orders: [],
    loading: true,
    emailModal: false,
    subject: '',
    body: '',
    editable: false,
    tray: true,
    trayEnabled: false,
    trayState: TrayState.NoTray,
    trayEditable: false,
    traySaving: false,
    trayDetails: {
      patientName: "",
      trayFrequency: "",
      trayCollectionSite: "",
      trayStartDate: "",
      pharmacistCheck: false,
      notRequired: false,
      enabled: true,
      MojoId: "",
      MojoVersion: 0
    },
    traysEnabled: false,
    pharmacy: null
  };

  async componentDidMount() {
    await this._setPatientData();
  }

  async _setPatientData() {
    let mojoId = this.props.location.search;
    let data = await remediApi.getPatientDetails(mojoId);

    let patient = data[0];

    const params = new URLSearchParams(mojoId);
    const id = params.get('id'); // bar

    let tray = await remediApi.getPatientTray(id);

    let orders = await remediApi.getOrdersForPatient(mojoId);

    if (patient && !patient.medications) {
      patient.medications = [];
    }

    if (!orders) {
      orders = [];
    }

    let trayFrequency = tray.length > 0 ? tray[0].frequency : "";
    let trayCollectionSite = tray.length > 0 ? tray[0].collectionSite : "";
    let trayStartDate = tray.length > 0 ? tray[0].startDate : ""
    let pharmacistCheck = tray.length > 0 ? tray[0].pharmacistCheck : false
    let notRequired = tray.length > 0 ? tray[0].notRequired : false
    let enabled = tray.length > 0 ? tray[0].enabled : true
    let trays = tray.length > 0 ? tray[0].trays : null;
    let trayMojoId = tray.length > 0 ? tray[0].MojoId : null;
    let trayMojoVersion = tray.length > 0 ? tray[0].MojoVersion : 0;

    let traysEnabled = false;
    let pharmacy = {};
    if ('pharmacy' in localStorage) {
      pharmacy = JSON.parse(localStorage.getItem('pharmacy'));
      if (pharmacy.services.includes('trays')) { traysEnabled = true; }
    }

    if (patient) {
      this.setState({
        id: patient.MojoId,
        version: patient.MojoVersion,
        firstName: patient.firstname,
        lastName: patient.lastname,
        dob: patient.dob,
        phoneNumber: patient.phoneNumber,
        address: patient.address,
        postcode: patient.postcode,
        rxMethod: patient.rxMethod,
        surgery: patient.surgery,
        email: patient.email,
        medications: patient.medications,
        pharmacyId: patient.pharmacyId,
        tray: patient.tray ?? true,
        orders: orders,
        loading: false,
        trayDetails: {
          patientName: patient.firstname + ' ' + patient.lastname,
          frequency: trayFrequency,
          frequencyState: null,
          collectionSite: trayCollectionSite,
          collectionSiteState: null,
          startDate: trayStartDate,
          startDateState: null,
          pharmacistCheck: pharmacistCheck,
          notRequired: notRequired,
          enabled: enabled,
          trays: trays,
          MojoId: trayMojoId,
          MojoVersion: trayMojoVersion
        },
        trayState: tray.length > 0 ? TrayState.Existing : TrayState.NoTray,
        trayEnabled: tray.length > 0 ? true : false,
        traysEnabled: traysEnabled,
        pharmacy: pharmacy
      });
    }
  }

  _validateTrayForm = async () => {
    let newState = this.state.trayDetails;
    let trayValid = true;
    if (newState.frequency === "") {
      newState.frequencyState = "invalid";
      trayValid = false;
    } else {
      newState.frequencyState = "valid";
    }
    if (newState.collectionSite === "") {
      newState.collectionSiteState = "invalid";
      trayValid = false;
    } else {
      newState.collectionSiteState = "valid";
    }
    if (newState.startDate === "") {
      newState.startDateState = "invalid";
      trayValid = false;
    } else {
      newState.startDateState = "valid";
    }

    this.setState({
      trayDetails: newState
    });

    if (trayValid) {
      this.setState({ traySaving: true });
      await this._addTray();
    }
  }

  async _addTray() {
    var options = {};
    let success = true;

    success = await remediApi.addPatientTray(this.state.trayDetails, this.state.id, this.state.pharmacyId);

    if (!success) {
      this._notify('Patient tray creation failed, please try again.');

      return;
    }

    options = {
      place: 'br',
      message: (
        <div>
          <div>
            Patient tray added.
          </div>
        </div>
      ),
      type: "success",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3
    }
    this.refs.notify.notificationAlert(options);
    this._setPatientData();
    this.setState({ trayEditable: false, traySaving: false });
  }
  async _updateTray() {
    this.setState({ traySaving: true });
    var options = {};
    let success = true;

    success = await remediApi.updatePatientTray(this.state.trayDetails, this.state.id, this.state.pharmacyId);

    if (!success) {
      this._notify('Patient tray update failed, please try again.');

      return;
    }

    options = {
      place: 'br',
      message: (
        <div>
          <div>
            Patient tray updated.
          </div>
        </div>
      ),
      type: "success",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3
    }
    this.refs.notify.notificationAlert(options);
    this._setPatientData();
    this.setState({ trayEditable: false, traySaving: false });
  }

  _form = (e, stateName) => {
    let newState = this.state.trayDetails;

    if (stateName === "pharmacistCheck" || stateName === "notRequired" || stateName === "enabled") {
      newState[stateName] = e.target.checked;
    }
    else if (e.target.value === "") {
      newState[stateName + "State"] = "invalid";
    } else {
      newState[stateName] = e.target.value;
      newState[stateName + "State"] = "valid";
    }

    this.setState({
      trayDetails: newState
    });
  };


  async _update() {
    var options = {};
    let success = true;

    success = await remediApi.updatePatientName(this.state.id, this.state.version, this.state.firstName, this.state.lastName);

    if (!success) {
      this._notify('Patient name update failed, please try again.');

      return;
    }

    options = {
      place: 'br',
      message: (
        <div>
          <div>
            Patient name updated.
          </div>
        </div>
      ),
      type: "success",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3
    }
    this.refs.notify.notificationAlert(options);
    this._setPatientData();
    this.setState({ editable: false });
    //window.location.reload(true);
  }

  _populateOrders = async () => {
    let mojoId = this.props.location.search;
    let orders = await remediApi.getOrdersForPatient(mojoId);

    if (orders) {
      this.setState({ orders: orders });
    }
  }

  _notify(message) {
    let options = {
      place: 'br',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: "danger",
      autoDismiss: 7
    };
    this.refs.notify.notificationAlert(options);
  }

  render() {
    return (
      <>
        <SimpleHeader name="Patient Details" parentName="Patients" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0" style={{ float: 'left', marginTop: 4 }}>{this.state.loading ?
                      <Spinner color="primary" /> :
                      <span>{this.state.firstName} {this.state.lastName}</span>}
                    </h3>
                    <span style={{ float: 'right' }}>
                      {this.state.editable ? <Button className="btn btn-default btn-sm btn-icon-only" onClick={async (e) => {
                        await this._update();
                      }}>
                        <span className="btn-inner--icon"><i className="fas fa-save"></i></span>
                      </Button> : <span />}
                      <Button className="btn btn-info btn-sm btn-icon-only" onClick={async (e) => {

                        if (this.state.editable) {
                          await this._setPatientData();
                        }

                        this.setState(prevState => ({ editable: !prevState.editable }));
                      }}>
                        {this.state.editable ? <span className="btn-inner--icon"><i className="fas fa-times"></i></span>
                          : <span className="btn-inner--icon"><i className="fas fa-edit"></i></span>}
                      </Button>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          size="sm"
                          //color="neutral"
                          className="mr-0 btn btn-default btn-sm btn-icon-only"
                        >
                          <i className="fas fa-ellipsis-h" />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            onClick={e => this.setState({ emailModal: true })}
                          >
                            <i className="fas fa-envelope" />
                            Email Patient
                          </DropdownItem>
                          {this.state.traysEnabled && this.state.trayDetails.MojoId === null && <DropdownItem
                            onClick={e =>
                              this.setState(prevState => ({ trayEditable: true, trayEnabled: true, trayState: TrayState.New }))
                            }
                          >
                            <i className="fas fa-inbox"></i>
                            Enable Tray
                          </DropdownItem>}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </span>
                  </CardHeader>
                  <CardBody>
                    <Form className="needs-validation" noValidate>
                      <div className="form-row">
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            First name
                          </label>
                          <Input
                            type="text"
                            value={this.state.firstName}
                            onChange={(e) => { this.setState({ firstName: e.target.value }) }}
                            readOnly={!this.state.editable}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </Col>
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom02"
                          >
                            Last name
                          </label>
                          <Input
                            type="text"
                            value={this.state.lastName}
                            onChange={(e) => { this.setState({ lastName: e.target.value }) }}
                            readOnly={!this.state.editable}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </Col>
                      </div>
                      <div className="form-row">
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Email Address
                          </label>
                          <Input
                            type="text"
                            value={this.state.email}
                            readOnly={true}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </Col>
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Date of Birth
                          </label>
                          <Input
                            type="text"
                            value={this.state.dob}
                            readOnly={true}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </Col>
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom02"
                          >
                            Phone Number
                          </label>
                          <Input
                            type="text"
                            value={this.state.phoneNumber}
                            readOnly={true}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </Col>
                      </div>
                      <div className="form-row">
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom03"
                          >
                            Address
                          </label>
                          <Input
                            type="text"
                            value={this.state.address}
                            readOnly={true}
                          />
                          <div className="invalid-feedback">
                            Please provide a valid city.
                          </div>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom03"
                          >
                            Postcode
                          </label>
                          <Input
                            type="text"
                            value={this.state.postcode}
                            readOnly={true}
                          />
                          <div className="invalid-feedback">
                            Please provide a valid city.
                          </div>
                        </Col>
                      </div>
                      <div className="form-row">
                        {/* <Col className="mb-3" md="6">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustom03"
                      >
                        Collection/Delivery
                      </label>
                      <Input
                        type="text"
                        value={this.state.rxMethod}
                        readOnly={true}
                      />
                      <div className="invalid-feedback">
                        Please provide a valid city.
                      </div>
                    </Col> */}
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom02"
                          >
                            Surgery
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue={this.state.surgery}
                            data={this.state.pharmacy ? this.state.pharmacy.surgeries.map((sug, key) => {
                              return { id: sug.name, text: sug.name }
                            }) : []}
                            disabled
                          />
                        </Col>
                        {/* <Col>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id="customCheck1"
                              type="checkbox"
                              checked={this.state.tray}
                              onChange={(e) => {
                                  this.setState({ tray: e.target.checked })
                              }}
                              disabled={!this.state.editable}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              Tray Patient
                            </label>
                          </div>
                        </Col> */}
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          {this.state.trayEnabled && <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0" style={{ float: 'left', marginTop: 4 }}>{this.state.loading ?
                      <Spinner color="primary" /> :
                      <span>Tray Details</span>}
                    </h3>
                      <span style={{ float: 'right' }}>
                      {this.state.traySaving ?
                      <Spinner color="primary" style={{ width: "1rem", height: "1rem", marginRight: "5px" }} /> :
                        <div>
                          {this.state.trayEditable ? <Button className="btn btn-default btn-sm btn-icon-only" onClick={async (e) => {
                            this.state.trayState === TrayState.New ? await this._validateTrayForm() : await this._updateTray();
                          }}>
                            <span className="btn-inner--icon"><i className="fas fa-save"></i></span>
                          </Button> : <span />}
                          <Button className="btn btn-info btn-sm btn-icon-only" onClick={async (e) => {

                            if (this.state.trayEditable) {
                              this.setState({ traySaving: true })
                              await this._setPatientData();
                              this.setState({ traySaving: false })
                            }
                            var nextTrayState = this.state.trayState === TrayState.New ? TrayState.NoTray : TrayState.Existing;
                            this.setState(prevState => ({ trayEditable: !prevState.trayEditable, trayEnabled: prevState.trayEnabled, trayState: nextTrayState }))
                          }}>
                            {this.state.trayEditable ? <span className="btn-inner--icon"><i className="fas fa-times"></i></span>
                              : <span className="btn-inner--icon"><i className="fas fa-edit"></i></span>}
                          </Button></div>}
                      </span>
                  </CardHeader>
                  <CardBody>
                    <Form className="needs-validation" noValidate>
                      <div className="form-row">
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Frequency
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue={this.state.trayDetails.frequency}
                            options={{
                              placeholder: "Select",
                              disabled: !this.state.trayEditable
                            }}
                            data-minimum-results-for-search="Infinity"
                            data={[
                              { id: "Weekly", text: "Weekly" },
                              { id: "Monthly", text: "Monthly" },
                            ]}
                            onChange={e => {
                              this._form(e, "frequency")
                            }}
                          />
                        </Col>
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom02"
                          >
                            Collection Site
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue={this.state.trayDetails.collectionSite}
                            options={{
                              placeholder: "Select",
                              disabled: !this.state.trayEditable
                            }}
                            data-minimum-results-for-search="Infinity"
                            data={[
                              { id: "DL", text: "Delivery (DL)" },
                              { id: "LV", text: "Collection Lisvane (LV)" },
                              { id: "67", text: "Collection Thornhill (67)" },
                            ]}
                            onChange={e => {
                              this._form(e, "collectionSite")
                            }}
                          />
                        </Col>
                      </div>
                      <div className="form-row">
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Start Date
                          </label>
                          <Input
                            type="date"
                            value={this.state.trayDetails.startDate}
                            readOnly={!this.state.trayEditable}
                            valid={
                              this.state.trayDetails.startDateState === "valid"
                            }
                            invalid={
                              this.state.trayDetails.startDateState === "invalid"
                            }
                            onChange={e => {
                              this._form(e, "startDate")
                            }}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </Col>
                      </div>
                      <div className="form-row">
                        <Col className="mb-3" md="12">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Pharmacist Check
                          </label>
                          <div>
                            <label className="custom-toggle">
                              <input
                                type="checkbox"
                                disabled={!this.state.trayEditable}
                                checked={this.state.trayDetails.pharmacistCheck}
                                onChange={(e) => {
                                  this._form(e, "pharmacistCheck")
                                }}
                              />
                              <span className="custom-toggle-slider rounded-circle" />
                            </label>
                          </div>
                        </Col>
                      </div>
                      <div className="form-row">
                        <Col className="mb-3" md="12">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Not Required
                          </label>
                          <div>
                            <label className="custom-toggle">
                              <input
                                type="checkbox"
                                disabled={!this.state.trayEditable}
                                checked={this.state.trayDetails.notRequired}
                                onChange={(e) => {
                                  this._form(e, "notRequired")
                                }}
                              />
                              <span className="custom-toggle-slider rounded-circle" />
                            </label>
                          </div>
                        </Col>
                      </div>
                      <div className="form-row">
                        <Col className="mb-3" md="12">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Enabled
                          </label>
                          <div>
                            <label className="custom-toggle">
                              <input
                                type="checkbox"
                                disabled={!this.state.trayEditable}
                                checked={this.state.trayDetails.enabled}
                                onChange={(e) => {
                                  this._form(e, "enabled")
                                }}
                              />
                              <span className="custom-toggle-slider rounded-circle" />
                            </label>
                          </div>
                        </Col>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>}
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0" style={{ float: 'left', marginTop: 4 }}>{this.state.loading ?
                      <Spinner color="primary" /> :
                      <span>Repeats</span>}
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                      <Tab label={`Medications (${this.state.medications.length})`}>
                        <ToolkitProvider
                          keyField="id"
                          data={this.state.medications}
                          columns={[
                            {
                              dataField: "name",
                              text: "Name",
                              sort: true
                            },
                            {
                              dataField: "recurrence",
                              text: "Recurrence",
                              sort: true
                            }
                          ]}
                          search
                        >
                          {props => (
                            <div className="py-4 table-responsive">
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1"
                              >
                                <label>
                                  Search:
                                  <SearchBar
                                    className="form-control-sm"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                              {this.state.loading ? <Spinner style={{ marginLeft: 15 }} color="primary" /> :
                                <BootstrapTable
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  pagination={pagination}
                                  bordered={false}
                                  loading={true}
                                  hover={true}
                                />}
                            </div>
                          )}
                        </ToolkitProvider>
                      </Tab>
                      <Tab label={`Received Orders (${this.state.orders.filter(item => {
                        return item.orderStatus != 'collected' && item.orderStatus != 'delivered'
                      }).length})`}>
                        <ToolkitProvider
                          keyField="MojoId"
                          data={this.state.orders.filter(item => {
                            return item.orderStatus != 'collected' && item.orderStatus != 'delivered'
                          })}
                          columns={[
                            {
                              dataField: 'select',
                              isDummyField: true,
                              text: 'Select',
                              formatter: (cellContent, row) => {
                                return (
                                  <Button className="btn btn-default btn-sm btn-icon-only" onClick={(e) => {
                                    //console.log(row._id);
                                    this.props.history.push('/admin/order?id=' + row.MojoId)
                                  }}>
                                    <span className="btn-inner--icon"><i className="fas fa-search"></i></span>
                                  </Button>
                                );
                              },
                              editable: false
                            },
                            {
                              dataField: "orderNumber",
                              text: "Order Number",
                              sort: true,
                              editable: false
                            },
                            {
                              dataField: "orderDate",
                              text: "Order Date",
                              sort: true,
                              editable: false
                            },
                            {
                              dataField: 'rxMethod',
                              isDummyField: true,
                              text: 'Collection/Delivery',
                              editable: false,
                              formatter: (cellContent, row) => {
                                if (row.rxMethod == 'Delivery') {
                                  return (
                                    <span>Delivery</span>
                                  );
                                } else {
                                  return (
                                    <span>Collection</span>
                                  );
                                }
                              }
                            },
                            // {
                            //     dataField: "orderStatus",
                            //     text: "Order Status",
                            //     sort: true,
                            //     editor: {
                            //         type: Type.SELECT,
                            //         getOptions: (setOptions, { row, column }) => {
                            //             if(row.rxMethod == 'Delivery') {
                            //                 return [{
                            //                     value: 'new',
                            //                     label: 'New'
                            //                   }, {
                            //                     value: 'pending',
                            //                     label: 'Pending'
                            //                   }, {
                            //                     value: 'readyDelivery',
                            //                     label: 'Ready to Deliver'
                            //                   }, {
                            //                     value: 'delivered',
                            //                     label: 'Delivered'
                            //                   }]
                            //             } else {
                            //                 return [{
                            //                     value: 'new',
                            //                     label: 'New'
                            //                   }, {
                            //                     value: 'pending',
                            //                     label: 'Pending'
                            //                   }, {
                            //                     value: 'readyCollect',
                            //                     label: 'Ready to Collect'
                            //                   }, {
                            //                     value: 'collected',
                            //                     label: 'Collected'
                            //                   }]
                            //             }
                            //         }
                            //       },
                            //       classes: (cell, row, rowIndex, colIndex) =>
                            //       (cell == 'new' ? 'patient-order-row-new' : 
                            //        cell == 'pending' ? 'patient-order-row-pending' :
                            //        cell == 'readyCollect' ? 'patient-order-row-ready' :
                            //        cell == 'readyDelivery' ? 'patient-order-row-ready' :
                            //        cell == 'delivered' ? 'patient-order-row-complete' :
                            //        cell == 'collected' ? 'patient-order-row-complete' : ''),
                            //       formatter: (cellContent, row) => {
                            //         return (
                            //           row.orderStatus == 'new' ? 'New' : 
                            //           row.orderStatus == 'pending' ? 'Pending' :
                            //           row.orderStatus == 'readyCollect' ? 'Ready to Collect' :
                            //           row.orderStatus == 'readyDelivery' ? 'Ready to Deliver' :
                            //           row.orderStatus == 'delivered' ? 'Delivered' :
                            //           row.orderStatus == 'collected' ? 'Collected' :
                            //           'Invalid Status'
                            //         );
                            //       }
                            // }
                          ]}
                          search
                        >
                          {props => (
                            <div className="py-4 table-responsive">
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1"
                              >
                                <label>
                                  Search:
                                  <SearchBar
                                    className="form-control-sm"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                              {this.state.loading ? <Spinner style={{ marginLeft: 15 }} color="primary" /> :
                                <BootstrapTable
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  pagination={pagination}
                                  bordered={false}
                                  loading={true}
                                  hover={true}
                                  cellEdit={cellEditFactory({
                                    mode: 'click', blurToSave: true, afterSaveCell: async (oldValue, newValue, row, column) => {
                                      if (oldValue != newValue) {
                                        let success = await remediApi.updateOrderStatus(row.MojoId, row.MojoVersion, newValue);
                                        if (success) {
                                          var options = {};
                                          options = {
                                            place: 'br',
                                            message: (
                                              <div>
                                                <div>
                                                  Status successfully updated
                                                </div>
                                              </div>
                                            ),
                                            type: "success",
                                            icon: "now-ui-icons ui-1_bell-53",
                                            autoDismiss: 3
                                          }
                                          this.refs.notify.notificationAlert(options);
                                        }
                                        await this._populateOrders();
                                      }
                                    }
                                  })}
                                  defaultSorted={[{
                                    dataField: 'orderNumber',
                                    order: 'desc'
                                  }]}
                                />}
                            </div>
                          )}
                        </ToolkitProvider>
                      </Tab>
                      <Tab label={`Processed Orders (${this.state.orders.filter(item => {
                        return item.orderStatus === 'collected' || item.orderStatus === 'delivered'
                      }).length})`}>
                        <ToolkitProvider
                          keyField="MojoId"
                          data={this.state.orders.filter(item => {
                            return item.orderStatus === 'collected' || item.orderStatus === 'delivered'
                          })}
                          columns={[
                            {
                              dataField: 'select',
                              isDummyField: true,
                              text: 'Select',
                              formatter: (cellContent, row) => {
                                return (
                                  <Button className="btn btn-default btn-sm btn-icon-only" onClick={(e) => {
                                    //console.log(row._id);
                                    this.props.history.push('/admin/order?id=' + row.MojoId)
                                  }}>
                                    <span className="btn-inner--icon"><i className="fas fa-search"></i></span>
                                  </Button>
                                );
                              },
                              editable: false
                            },
                            {
                              dataField: "orderNumber",
                              text: "Order Number",
                              sort: true,
                              editable: false
                            },
                            {
                              dataField: "orderDate",
                              text: "Order Date",
                              sort: true,
                              editable: false
                            },
                            {
                              dataField: "dispenseDate",
                              text: "Dispense Date",
                              sort: true,
                              editable: false
                            },
                            {
                              dataField: 'rxMethod',
                              isDummyField: true,
                              text: 'Collection/Delivery',
                              editable: false,
                              formatter: (cellContent, row) => {
                                if (row.rxMethod == 'Delivery') {
                                  return (
                                    <span>Delivery</span>
                                  );
                                } else {
                                  return (
                                    <span>Collection</span>
                                  );
                                }
                              }
                            }
                          ]}
                          search
                        >
                          {props => (
                            <div className="py-4 table-responsive">
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1"
                              >
                                <label>
                                  Search:
                                  <SearchBar
                                    className="form-control-sm"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                              {this.state.loading ? <Spinner style={{ marginLeft: 15 }} color="primary" /> :
                                <BootstrapTable
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  pagination={pagination}
                                  bordered={false}
                                  loading={true}
                                  hover={true}
                                  cellEdit={cellEditFactory({
                                    mode: 'click', blurToSave: true, afterSaveCell: async (oldValue, newValue, row, column) => {
                                      if (oldValue != newValue) {
                                        let success = await remediApi.updateOrderStatus(row.MojoId, row.MojoVersion, newValue);
                                      }
                                    }
                                  })}
                                  defaultSorted={[{
                                    dataField: 'orderNumber',
                                    order: 'desc'
                                  }]}
                                />}
                            </div>
                          )}
                        </ToolkitProvider>
                      </Tab>
                    </Tabs>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
        <div style={{ zIndex: 1051, position: 'fixed' }}>
          <NotificationAlert ref="notify" />
        </div>
        <Modal size="lg" isOpen={this.state.emailModal} toggle={() => { this.setState({ emailModal: false }) }}>
          <ModalHeader toggle={() => { this.setState({ emailModal: false }) }}>Email Patient</ModalHeader>
          <ModalBody>
            <label
              className="form-control-label"
              htmlFor="validationCustom03"
            >
              To:
            </label>
            <Input
              type="text"
              readOnly={true}
              value={this.state.email}
            />
            <label style={{ marginTop: 10 }}
              className="form-control-label"
              htmlFor="validationCustom03"
            >
              Subject:
            </label>
            <Input
              type="text"
              onChange={(e) => this.setState({ subject: e.target.value })}
            />
            <Input style={{ marginTop: 10 }} readOnly={true} value={'Hi ' + this.state.firstName + ','} />
            <Input
              style={{ marginTop: 10 }}
              id="emailTextArea"
              rows="15"
              type="textarea"
              onChange={(e) => this.setState({ body: e.target.value })}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="btn btn-icon btn-info" onClick={() => { this.setState({ emailModal: false }) }}>Close</Button>
            <Button color="btn btn-icon btn-default" onClick={async (e) => {
              e.preventDefault();
              let result = await remediApi.sendPatientEmail(
                this.state.firstName + ' ' + this.state.lastName,
                this.state.firstName,
                this.state.email,
                this.state.pharmacyId,
                this.state.subject,
                this.state.body);

              var options = {};

              if (result != null) {
                this.setState({ emailModal: false });

                options = {
                  place: 'br',
                  message: (
                    <div>
                      <div>
                        Email request processed.
                      </div>
                    </div>
                  ),
                  type: "success",
                  icon: "now-ui-icons ui-1_bell-53",
                  autoDismiss: 3
                }
                this.refs.notify.notificationAlert(options);
              } else {
                let options = {
                  place: 'br',
                  message: (
                    <div>
                      <div>
                        Email request failed, please try again.
                      </div>
                    </div>
                  ),
                  type: "danger",
                  autoDismiss: 7
                };
                this.refs.notify.notificationAlert(options);
              }
            }}>Send</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default PatientDetails;
