/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import { remediApi } from '../../../remediApi';
// nodejs library that concatenates classes
import classnames from "classnames";

import NotificationAlert from "react-notification-alert";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  FormFeedback,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Spinner,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";

class Login extends React.Component {
  constructor(props) {
    super(props);

    remediApi.logout();

    this.state = {
      email: '',
      password: '',
      ƒ: false,
      error: false,
      forgottenPasswordModal: false,
      forgottenPasswordEmail: ''
    };
  }

  notify = place => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Forgotten Password Request
          </span>
          <span data-notify="message">
            Request received, please check your email for a password reset link.
          </span>
        </div>
      ),
      type: "success",
      icon: "ni ni-email-83",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  async handleSubmit(e) {
    e.preventDefault();

    const { email, password } = this.state;

    // stop here if form is invalid
    if (!(email && password)) {
      return;
    }

    this.setState({ loading: true });
    let loggedIn = await remediApi.login(email, password);

    if(loggedIn) {
      let pharmacy = await remediApi.getPharmacy();
      localStorage.setItem('email', email);
      localStorage.setItem('pharmacy', JSON.stringify(pharmacy));
      const { from } = this.props.location.state || { from: { pathname: "/admin/patients" } };
      this.props.history.push(from);
    } else {
      this.setState({ error: true, loading: false });
    }
  }

  async handleForgottemPasswordSubmit(e) {
    e.preventDefault();

    const { forgottenPasswordEmail } = this.state;

    // stop here if form is invalid
    if (!(forgottenPasswordEmail)) {
      return;
    }

    this.setState({ loading: true });
    await remediApi.forgottenPasswordRequest(forgottenPasswordEmail);

    this.setState({ loading: false, forgottenPasswordModal: false });
    this.notify("tc");
  }

  render() {
    const { email, password, loading, error, forgottenPasswordEmail } = this.state;
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AuthHeader
          title="Login"
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Sign in with credentials</small>
                  </div>
                  {this.state.loading ? <div className="text-center"><Spinner className="text-center" color="primary" style={{ marginRight: "5px" }} /></div> : 
                  <Form role="form" onSubmit={ (e) => this.handleSubmit(e) }>
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: this.state.focusedEmail
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input style={{paddingLeft:10}}
                          name='email'
                          placeholder="Email"
                          type="email"
                          // onFocus={() => this.setState({ focusedEmail: true })}
                          // onBlur={() => this.setState({ focusedEmail: false })}
                          value={email} 
                          onChange={this.handleChange}                          
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input invalid  style={{paddingLeft:10}}
                          name='password'
                          placeholder="Password"
                          type="password"
                          // onFocus={() =>
                          //   this.setState({ focusedPassword: true })
                          // }
                          // onBlur={() =>
                          //   this.setState({ focusedPassword: false })
                          // }
                          value={password} 
                          onChange={this.handleChange}
                        />
                        
                      </InputGroup>
                      {error ? <div style={{marginTop:10, fontSize:12, color:'red'}}>Invalid Credentials</div> : <span/>}
                    </FormGroup>
                    <div className="text-center">
                      <Button className="my-4 btn-default" color="info" type="submit" disabled={loading}>
                        Sign in
                      </Button>
                    </div>
                  </Form>}
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-light"
                    onClick={
                      e => { 
                        e.preventDefault()
                        this.setState({forgottenPasswordModal: true});
                      }
                    }
                  >
                    <small>Forgot password?</small>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Modal size="lg" isOpen={this.state.forgottenPasswordModal} toggle={() => {this.setState({forgottenPasswordModal: false})}}>
          <ModalHeader >Forgotten Password</ModalHeader>
          <ModalBody>
            <Form role="form">
              <FormGroup
                className={classnames("mb-3", {
                  focused: this.state.focusedEmail
                })}
              >
                <InputGroup className="input-group-merge input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name='forgottenPasswordEmail'
                    placeholder="Email"
                    type="email"
                    // onFocus={() => this.setState({ focusedEmail: true })}
                    // onBlur={() => this.setState({ focusedEmail: false })}
                    value={forgottenPasswordEmail} 
                    onChange={this.handleChange}                          
                  />
                </InputGroup>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="btn btn-default btn-info" disabled={loading} onClick={(e) => this.handleForgottemPasswordSubmit(e)}>Submit</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Login;
