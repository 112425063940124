/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

import DateTime from "react-datetime"

import NotificationAlert from 'react-notification-alert';

import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer';

import { remediApi } from '../../../remediApi';

import { ExportToCsv } from 'ts-export-to-csv';

import moment from 'moment'

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  Col,
  UncontrolledTooltip,
  Modal, ModalHeader, ModalBody, ModalFooter, Label
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  )
});

const { SearchBar } = Search;

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF'
  },
  section: {
    margin: 4,
    padding: 8
  },
  separator: {
    borderTop: '2px solid black',
    marginLeft: 8,
    marginRight: 8
  },
  smallText: {
    fontSize: 10,
    fontWeight: 700,
    marginTop: 4,
    marginLeft: 10
  },
  largeText: {
    fontSize: 15,
    fontWeight: 400
  },
  headerAlignment: {
    flexDirection: 'row',
    margin: 8,
    padding: 8,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  meds: {
    marginLeft: 12,
    paddingTop: 12,
    paddingBottom: 12
  }
});

// Create Document Component
const MyDocument = (props) => (
  <Document>
    {props.data.prescriptions.map(function (prescription, idx) {
      return (<Page key={prescription.order.MojoId} size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image
            src={require("assets/img/remedi_logoV2.png")}
            style={{ width: 200 }}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.section}>
          <Text style={styles.smallText}>PRESCRIPTION ORDER DATE: {moment(prescription.order.orderDate).format('DD/MM/YYYY')}</Text>
        </View>
        <View style={styles.separator} />
        <View style={styles.section}>
          <Text style={styles.smallText}>SURGERY NAME: {prescription.order.surgery}</Text>
          <Text style={styles.smallText}>SURGERY ADDRESS: {prescription.order.surgeryAddress}</Text>
        </View>
        <View style={styles.separator} />
        <View style={styles.section}>
          <Text style={styles.smallText}>PATIENT NAME: {prescription.order.patientName}</Text>
          <Text style={styles.smallText}>DATE OF BIRTH: {moment(prescription.order.patientDob).format('DD/MM/YYYY')}</Text>
          <Text style={styles.smallText}>ADDRESS: {prescription.order.address ? prescription.order.address.replace(/(\r\n|\n|\r)/gm, " ") : ''} {prescription.order.postcode ? prescription.order.postcode : ''}</Text>
        </View>
        <View style={styles.separator} />
        <View style={styles.meds}>
          <Text style={styles.smallText}>MEDICATIONS:</Text>
          {prescription.order.medications.map(function (med, idx) {
            return (
              <View key={med.id}>
                <Text style={styles.smallText}>{med.name} - {med.recurrence}</Text>
              </View>
            )
          })}
        </View>
        {prescription.order.deliveryNotes ? 
        <span>
          <View style={styles.separator} />
          <View style={styles.section}>
            <Text style={styles.smallText}>ORDER NOTE:</Text>
            <Text style={styles.smallText}>{prescription.order.deliveryNotes}</Text>
          </View> 
        </span> : <span/>}
        <View style={styles.separator} />
        <View style={styles.section}>
          <Text style={styles.smallText}>TO BE COLLECTED BY: {props.data.pharmacy.name}, {props.data.pharmacy.address}</Text>
          <Text style={styles.smallText}>ON: {moment(prescription.surgeryCollectionDate).format('DD/MM/YYYY')}</Text>
        </View>
        <View style={styles.separator} />
        <View style={styles.section}>
          <Text style={styles.smallText}>COLLECTION/DELIVERY: {prescription.order.rxMethod}</Text>
          <Text style={styles.smallText}>ON: {moment(prescription.dispenseDate).format('DD/MM/YYYY')}</Text>
        </View>
      </Page>)
    })}
  </Document>
);

class Orders extends React.Component {
  state = {
    alert: null,
    data: [],
    processData: [],
    loading: true,
    modal: false,
    modalRow: null,
    processModal: false,
    processGenerated: false,
    resultsFound: true,
    processLoading: false,
    processing: false,
    hideButton: false,
    date: moment(),
    printData: null,
    chris: false,
    printing: false
  };

  _filterData = async () => {
    var processData = await remediApi.getOrdersToProcess(this.state.date.format("YYYY-MM-DD"))
    this.setState({ processData: processData, resultsFound: true }, () => {
      if (this.state.processData != null && this.state.processData.prescriptions.length > 0) {
        this.setState({ processGenerated: true, processLoading: false });
      } else {
        this.setState({ resultsFound: false, processLoading: false })
      }
    });
  }

  _printOrder = async (mojoId) => {
    this.setState({ printing: true }, async () => {
      var formattedId = `?id=${mojoId}`;
      var data = await remediApi.getPatientOrderDetails(formattedId);
      this.setState({ printData: data[0] }, () => {
        /* 
          TODO:
          There's some issue with Chrome and the print window firing before the state has populated the pdf doc.. timer needs to go.
        */
        setTimeout(() => {
           var doc = document.getElementById("single-print-item").contentWindow;
           doc.print();
           this.setState({ printing: false });
        }, 2000);
      });
    });
  }

  _populateOrders = async () => {
    let orders = await remediApi.getOrders();

    if (orders) {
      this.setState({ data: JSON.parse(orders), loading: false });
    } else {
      this.setState({ loading: false })
    }
  }

  async componentDidMount() {
    await this._populateOrders();
  }

  render() {
    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Orders" parentName="Remedi" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader style={{ backgroundColor: '#607d8b', color: 'white' }}>
                  <h3 className="mb-0" style={{ color: 'white' }}>Received Orders</h3>
                  <p className="text-sm mb-0">
                    {/* Requested orders currently in the status of <span style={{color:'#607d8b', fontWeight: 'bold'}}>New</span>, <span style={{color:'#FFC107', fontWeight: 'bold'}}>Pending</span> and ready for <span style={{color:'#03a9f4', fontWeight: 'bold'}}>Collection/Delivery</span>. */}
                    Lastest orders <span style={{ fontWeight: 'bold' }}>Received</span> through the Remedi mobile app.
                  </p>
                </CardHeader>
                <ToolkitProvider
                  keyField="_id"
                  data={this.state.data.filter(item => {
                    return item.orderStatus != 'collected' && item.orderStatus != 'delivered'
                  })}
                  columns={[
                    {
                      dataField: 'actions',
                      isDummyField: true,
                      text: 'Actions',
                      editable: false,
                      formatter: (cellContent, row) => {
                        return (
                          <>
                            <Button className="btn btn-default btn-sm btn-icon-only" onClick={(e) => {
                              this.props.history.push('/admin/order?id=' + row.MojoId)
                            }}>
                              <span className="btn-inner--icon"><i className="fas fa-search"></i></span>
                            </Button>
                          </>
                        );
                      }
                    },
                    {
                      dataField: "patientName",
                      text: "Name",
                      sort: true,
                      editable: false,
                    },
                    {
                      dataField: "_id",
                      text: "Id",
                      sort: true,
                      editable: false,
                      hidden: true
                    },
                    {
                      dataField: "orderNumber",
                      text: "Order Number",
                      sort: true,
                      editable: false
                    },
                    {
                      dataField: "orderDate",
                      text: "Order Date",
                      sort: true,
                      editable: false
                    },
                    {
                      dataField: 'rxMethod',
                      isDummyField: true,
                      text: 'Collection/Delivery',
                      editable: false,
                      formatter: (cellContent, row) => {
                        if (row.rxMethod == 'Delivery') {
                          return (
                            <span>Delivery</span>
                          );
                        } else {
                          return (
                            <span>Collection</span>
                          );
                        }
                      }
                    },
                    //   {
                    //     dataField: "orderStatus",
                    //     text: "Order Status",
                    //     sort: true,
                    //     editor: {
                    //         type: Type.SELECT,
                    //         getOptions: (setOptions, { row, column }) => {
                    //             if(row.rxMethod == 'Delivered') {
                    //                 return [{
                    //                     value: 'new',
                    //                     label: 'New'
                    //                   }, {
                    //                     value: 'pending',
                    //                     label: 'Pending'
                    //                   }, {
                    //                     value: 'readyDelivery',
                    //                     label: 'Ready to Deliver'
                    //                   }, {
                    //                     value: 'delivered',
                    //                     label: 'Delivered'
                    //                   }]
                    //             } else {
                    //                 return [{
                    //                     value: 'new',
                    //                     label: 'New'
                    //                   }, {
                    //                     value: 'pending',
                    //                     label: 'Pending'
                    //                   }, {
                    //                     value: 'readyCollect',
                    //                     label: 'Ready to Collect'
                    //                   }, {
                    //                     value: 'collected',
                    //                     label: 'Collected'
                    //                   }]
                    //             }
                    //         }
                    //       },
                    //       classes: (cell, row, rowIndex, colIndex) =>
                    //       (cell == 'new' ? 'patient-order-row-new' : 
                    //        cell == 'pending' ? 'patient-order-row-pending' :
                    //        cell == 'readyCollect' ? 'patient-order-row-ready' :
                    //        cell == 'readyDelivery' ? 'patient-order-row-ready' :
                    //        cell == 'delivered' ? 'patient-order-row-complete' :
                    //        cell == 'collected' ? 'patient-order-row-complete' : ''),
                    //       formatter: (cellContent, row) => {
                    //         return (
                    //           row.orderStatus == 'new' ? 'New' : 
                    //           row.orderStatus == 'pending' ? 'Pending' :
                    //           row.orderStatus == 'readyCollect' ? 'Ready to Collect' :
                    //           row.orderStatus == 'readyDelivery' ? 'Ready to Deliver' :
                    //           row.orderStatus == 'delivered' ? 'Delivered' :
                    //           row.orderStatus == 'collected' ? 'Collected' :
                    //           'Invalid Status'
                    //         );
                    //       }
                    // }
                  ]}
                  search
                >
                  {props => (
                    <div className="py-4 table-responsive">
                      {this.state.loading ? <div /> : <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label>
                          Search:
                          <SearchBar
                            className="form-control-sm"
                            placeholder=""
                            {...props.searchProps}
                          />
                          <Button className="btn btn-info btn-sm process-btn" style={{ marginLeft: 15 }} onClick={() => {
                            this.setState({ processModal: true, resultsFound: true });
                            // let selectedData = this.state.data.filter(item => {
                            //   return item._id === row._id 
                            // })[0];
                            // console.log(selectedData);
                            // this.setState({modalRow: selectedData, modal:true})
                          }}>
                            <span className="btn-inner--icon"><i className="fas fa-capsules"></i> Process Orders</span>
                          </Button>
                        </label>
                      </div>}
                      {this.state.loading ? <Spinner style={{ marginLeft: 15 }} color="primary" /> :
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                          loading={true}
                          hover={true}
                          cellEdit={cellEditFactory({
                            mode: 'click', blurToSave: true, afterSaveCell: async (oldValue, newValue, row, column) => {
                              if (oldValue != newValue) {
                                let success = await remediApi.updateOrderStatus(row.MojoId, row.MojoVersion, newValue);

                                if (success) {
                                  var options = {};
                                  options = {
                                    place: 'br',
                                    message: (
                                      <div>
                                        <div>
                                          Status successfully updated
                                              </div>
                                      </div>
                                    ),
                                    type: "success",
                                    icon: "now-ui-icons ui-1_bell-53",
                                    autoDismiss: 3
                                  }
                                  this.refs.notify.notificationAlert(options);
                                }

                                await this._populateOrders();
                              }
                            }
                          })}
                          defaultSorted={[{
                            dataField: 'orderNumber',
                            order: 'desc'
                          }]}
                        />}
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader style={{ backgroundColor: '#4caf50' }}>
                  <h3 className="mb-0" style={{ color: 'white' }}>Processed Orders</h3>
                  <p className="text-sm mb-0" style={{ color: 'white' }}>
                    Processed orders that have been <span style={{ fontWeight: 'bold' }}>Delivered</span> to, or <span style={{ fontWeight: 'bold' }}>Collected</span> by the patient.
                  </p>
                </CardHeader>
                <ToolkitProvider
                  keyField="_id"
                  data={this.state.data.filter(item => {
                    return item.orderStatus === 'collected' || item.orderStatus === 'delivered'
                  })}
                  columns={[
                    {
                      dataField: 'select',
                      isDummyField: true,
                      text: 'Actions',
                      editable: false,
                      formatter: (cellContent, row) => {
                        return (
                          <>
                            <Button className="btn btn-default btn-sm btn-icon-only" onClick={(e) => {

                              this.props.history.push('/admin/order?id=' + row.MojoId)
                            }}>
                              <span className="btn-inner--icon"><i className="fas fa-search"></i></span>
                            </Button>

                            <Button className="btn btn-default btn-sm btn-icon-only" onClick={() => { this._printOrder(row.MojoId); }}>
                              <span className="btn-inner--ion"><i className="fas fa-print"></i></span>
                            </Button>
                          </>
                        );
                      }
                    },
                    {
                      dataField: "patientName",
                      text: "Name",
                      sort: true,
                      editable: false,
                    },
                    {
                      dataField: "orderNumber",
                      text: "Order Number",
                      sort: true,
                      editable: false
                    },
                    {
                      dataField: "orderDate",
                      text: "Order Date",
                      sort: true,
                      editable: false
                    },
                    {
                      dataField: "dispenseDate",
                      text: "Dispense Date",
                      sort: true,
                      editable: false
                    },
                    {
                      dataField: 'rxMethod',
                      isDummyField: true,
                      text: 'Collection/Delivery',
                      editable: false,
                      formatter: (cellContent, row) => {
                        if (row.rxMethod == 'Delivery') {
                          return (
                            <span>Delivery</span>
                          );
                        } else {
                          return (
                            <span>Collection</span>
                          );
                        }
                      }
                    }
                  ]}
                  search
                >
                  {props => (
                    <div className="py-4 table-responsive">
                      {this.state.loading ? <div /> : <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label>
                          Search:
                          <SearchBar
                            className="form-control-sm"
                            placeholder=""
                            {...props.searchProps}
                          />
                        </label>
                      </div>}
                      {this.state.loading ? <Spinner style={{ marginLeft: 15 }} color="primary" /> :
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                          loading={true}
                          hover={true}
                          defaultSorted={[{
                            dataField: 'orderNumber',
                            order: 'desc'
                          }]}
                        />}
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
        </Container>
        <NotificationAlert ref="notify" />
        <div>
          <Modal size="sm" isOpen={this.state.printing} toggle={() => { this.setState({ printing: !this.state.printing }) }}>
            <ModalBody>
              <Spinner size="sm" /> Please wait
            </ModalBody>
          </Modal>

          <Modal size="lg" isOpen={this.state.modal} toggle={() => { this.setState({ modal: !this.state.modal }) }}>
            <ModalHeader >Prescription</ModalHeader>
            <ModalBody>

            </ModalBody>
            <ModalFooter>
              <Button color="btn btn-icon btn-info" onClick={() => { this.setState({ modal: !this.state.modal }) }}>Close</Button>
            </ModalFooter>
          </Modal>
          {!this.state.loading ? <Modal size="lg" isOpen={this.state.processModal} toggle={() => { this.setState({ processModal: !this.state.processModal }) }}>
            <ModalHeader toggle={() => { this.setState({ processModal: !this.state.processModal }) }}>Process Orders</ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <label style={{ marginRight: '0.5em', paddingTop: '0.2rem' }}>Select Date:</label>
                  <DateTime
                    inputProps={{
                      className: 'form-control patient-order-modal-search-date'
                    }}
                    value={this.state.date}
                    timeFormat={false}
                    dateFormat="DD/MM/YYYY"
                    closeOnSelect={true}
                    onChange={(date) => { this.setState({ date: date, resultsFound: true }) }} />
                  <Button style={{ marginLeft: '0.5em', height: '2rem' }} color="btn btn-info btn-sm" onClick={async () => {
                    this.setState({ processGenerated: false, processLoading: true, hideButton: false }, async () => {
                      await this._filterData();
                    });
                  }}>Generate</Button>
                  {this.state.processLoading ? <Spinner style={{ marginLeft: 15 }} color="primary" /> : <span />}
                </Row>
                <Row className="print-row">
                  {this.state.processGenerated ? <PDFViewer id="print-preview" children={<MyDocument date={this.state.date} data={this.state.processData} />} width='100%' height='500px' /> : <div />}
                </Row>
                {this.state.resultsFound ? <span /> : <Row style={{ marginTop: 50, marginLeft: 10, fontSize: 14 }}>No result found...</Row>}
              </Container>
            </ModalBody>
            <ModalFooter>
              {/* <Button color="btn btn-icon btn-info" onClick={async () => {
            var result = await remediApi.getSupplierOrder(this.state.date.format('YYYY-MM-DD'));
 
              const options = { 
                filename: 'supplierOrder_' + this.state.date.format('YYYY-MM-DD'),
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true, 
                showTitle: false,
                title: 'Supplier Order',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
              };
             
            const csvExporter = new ExportToCsv(options);
             
            csvExporter.generateCsv(result);
            }}>Download Supplier Order</Button> */}
              {this.state.processGenerated && !this.state.processing && !this.state.hideButton ? <Button color="btn btn-icon btn-info" onClick={async () => {
                this.setState({ processing: true, hideButton: true }, async () => {
                  let processed = await remediApi.processOrders(this.state.processData.prescriptions);
                  await this._populateOrders();

                  if (processed != null) {
                    this.setState({ processing: false }, () => {
                      var pri = document.getElementById("print-preview").contentWindow;
                      pri.focus();
                      pri.print();
                    });
                  } else {
                    //TODO throw error here
                  }
                })
              }}>Process Orders</Button> : <div />}
              {this.state.processing ? <Spinner style={{ marginLeft: 15, marginBottom: 15 }} color="primary" /> : <span />}
            </ModalFooter>
          </Modal> : <div />}
        </div>

        {/* Hidden print items */}
        <div style={{ display: 'none' }}>
          {this.state.printData != null ?
            <PDFViewer id="single-print-item" width="100%" height="300">
              <Document>
                <Page key={this.state.printData.MojoId} size="A4" style={styles.page}>
                  <View style={styles.section, styles.headerAlignment}>
                    <Image
                      src={require("assets/img/remedi_logoV2.png")}
                      style={{ width: 200 }}
                    />
                    <Text style={{
                      fontSize: 20,
                      fontWeight: 'bold',
                      color: '#2665b2',
                      textTransform: 'uppercase',
                    }}>
                      Processed
                        </Text>
                  </View>
                  <View style={styles.separator} />
                  <View style={styles.section}>
                    <Text style={styles.smallText}>PRESCRIPTION ORDER DATE: {moment(this.state.printData.orderDate).format('DD/MM/YYYY')}</Text>
                  </View>
                  <View style={styles.separator} />
                  <View style={styles.section}>
                    <Text style={styles.smallText}>SURGERY NAME: {this.state.printData.surgery}</Text>
                    <Text style={styles.smallText}>SURGERY ADDRESS: {this.state.printData.surgeryAddress}</Text>
                  </View>
                  <View style={styles.separator} />
                  <View style={styles.section}>
                    <Text style={styles.smallText}>PATIENT NAME: {this.state.printData.patientName}</Text>
                    <Text style={styles.smallText}>DATE OF BIRTH: {moment(this.state.printData.patientDob).format('DD/MM/YYYY')}</Text>
                    <Text style={styles.smallText}>ADDRESS: {this.state.printData.address ? this.state.printData.address.replace(/(\r\n|\n|\r)/gm, " ") : ''} {this.state.printData.postcode ? this.state.printData.postcode : ''}</Text>
                  </View>
                  <View style={styles.separator} />
                  <View style={styles.meds}>
                    <Text style={styles.smallText}>MEDICATIONS:</Text>
                    {this.state.printData.medications.map(function (med, idx) {
                      return (
                        <View key={med.id}>
                          <Text style={styles.smallText}>{med.name} - {med.recurrence}</Text>
                        </View>
                      )
                    })}
                  </View>
                  {this.state.printData.deliveryNotes ? 
                  <span>
                    <View style={styles.separator} />
                    <View style={styles.section}>
                      <Text style={styles.smallText}>ORDER NOTE:</Text>
                      <Text style={styles.smallText}>{this.state.printData.deliveryNotes}</Text>
                    </View> 
                  </span> : <span/>}
                  <View style={styles.separator} />
                  <View style={styles.section}>
                    <Text style={styles.smallText}>TO BE COLLECTED BY: {this.state.printData.name}, {this.state.printData.surgeryAddress}</Text>
                    <Text style={styles.smallText}>ON: {moment(this.state.printData.surgeryCollectionDate).format('DD/MM/YYYY')}</Text>
                  </View>
                  <View style={styles.separator} />
                  <View style={styles.section}>
                    <Text style={styles.smallText}>COLLECTION/DELIVERY: {this.state.printData.rxMethod}</Text>
                    <Text style={styles.smallText}>ON: {moment(this.state.printData.dispenseDate).format('DD/MM/YYYY')}</Text>
                  </View>
                </Page>
              </Document>
            </PDFViewer>
            : <div></div>}
        </div>
      </>
    );
  }
}

export default Orders;
