import history from './history'
import config from './config'
import { textSpanContainsPosition } from 'typescript';

export const remediApi = {
    login,
    logout,
    getPatients,
    getPatientDetails,
    getOrdersForPatient,
    updateOrderStatus,
    getOrders,
    getPatientOrderDetails,
    getSupplierOrder,
    getRxOrder,
    getPharmacy,
    processOrders,
    getOrdersToProcess,
    getWeeklyTrays,
    getDailyTrays,
    getTrays,
    getPatientTray,
    forgottenPasswordRequest,
    updateRxDate,
    updateLeadTime,
    updateRegistrationOpen,
    updatePatientName,
    updateTray,
    updateMultiTray,
    addPatient,
    addPatientTray,
    updatePatientTray,
    updateAvatar,
    updateLogo,
    sendPatientEmail,
    getSurgeries,
    updateSurgeries
};

async function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/getToken`, requestOptions);

    let token = await response.text();
    
    if (!response.ok) {
        return false;
    } else if(token) {
        localStorage.setItem('token', token);
        return true;
    }

    return false;
}

async function forgottenPasswordRequest(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/forgottenPasswordRequest`, requestOptions);
    
    if (response.ok) {
        return true;
    }
    
    return false;
}

function logout() {
    // remove user from local storage to log user out
    let token = localStorage.getItem('token');

    if(token) {
        localStorage.removeItem('token');
    }

    history.push('/auth/login');
}

async function getSurgeries() {
    let requestOptions = _buildRequestOptions();

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/getSurgeries`, requestOptions);
    let surgeries = await _handleResponse(response);

    if (surgeries) {
        return JSON.parse(surgeries);
    }
    return null;
}

async function getPatients() {
    let requestOptions = _buildRequestOptions();

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/getPatients`, requestOptions);
    let patients = await _handleResponse(response);

    if (patients) {
        return JSON.parse(patients);
    }
    return null;
}

async function getOrders() {
    let requestOptions = _buildRequestOptions();

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/getOrders`, requestOptions);
    return await _handleResponse(response);
}

async function getDailyTrays(date) {
    let requestOptions = _buildRequestOptions();

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/trays/getFiltered/${date}`, requestOptions);
    
    let weeklyTrays = await _handleResponse(response);

    if (weeklyTrays) {
        return JSON.parse(weeklyTrays);
    }
    return null;
}


async function getWeeklyTrays(date) {
    let requestOptions = _buildRequestOptions();

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/trays/week/${date}`, requestOptions);
    
    let weeklyTrays = await _handleResponse(response);

    if (weeklyTrays) {
        return JSON.parse(weeklyTrays);
    }
    return null;
}

async function getTrays() {
    let requestOptions = _buildRequestOptions();

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/getTrays`, requestOptions);
    
    let trays = await _handleResponse(response);

    if (trays) {
        return JSON.parse(trays);
    }
    return null;
}

async function updateTray(tray) {
    var token = localStorage.getItem('token');
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(tray)
    };

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/trays/modify/?id=${tray.mojoId}&version=${tray.mojoVersion}`, requestOptions);
    return await _handleResponse(response);
}

async function updateMultiTray(tray) {
    var token = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(tray)
    };

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/tray/update`, requestOptions);
    
    let text = await response.text();
    const data = text;// && JSON.parse(text);

    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
            window.location.reload(true);
        } else if (response.status === 409) {
            return '409:' + text;
        }
    }

    return data;
}

async function getPatientTray(patientId) {
    let requestOptions = _buildRequestOptions();

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/getPatientTray/?patientId=${patientId}`, requestOptions);
    let patientTray = await _handleResponse(response);

    if (patientTray) {
        return JSON.parse(patientTray);
    }
    return null;
}

async function getPatientDetails(mojoId) {
    let requestOptions = _buildRequestOptions();

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/getPatientDetails${mojoId}`, requestOptions);
    let patientDetails = await _handleResponse(response)

    if (patientDetails) {
        return JSON.parse(patientDetails);
    }
    return null;
}

async function getPatientOrderDetails(mojoId) {
    let requestOptions = _buildRequestOptions();

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/getPatientOrderDetails${mojoId}`, requestOptions);
    let patientDetails = await _handleResponse(response)

    if (patientDetails) {
        return JSON.parse(patientDetails);
    }
    return null;
}

async function getOrdersForPatient(mojoId) {
    let requestOptions = _buildRequestOptions();

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/getOrdersForPatient${mojoId}`, requestOptions);
    let patientOrders = await _handleResponse(response);

    if (patientOrders) {
        return JSON.parse(patientOrders);
    }
    return null;
}

async function updateOrderStatus(id, version, orderStatus) {
    var token = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id, version, orderStatus })
    };

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/updateOrderStatus`, requestOptions);
    return await _handleResponse(response);
}

async function updateRxDate(id, version, date, patientName, orderNumber, email, pharmacyId) {
    var token = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id, version, date, patientName, orderNumber, email, pharmacyId })
    };

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/updateRxDate`, requestOptions);
    return await _handleResponse(response);
}

async function updateLeadTime(id, version, leadTime) {
    var token = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id, version, leadTime })
    };
    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/updateLeadTime`, requestOptions);
    return await _handleResponse(response);
}

async function updateRegistrationOpen(id, version, registrationOpen) {
    var token = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id, version, registrationOpen })
    };
    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/updateRegistrationOpen`, requestOptions);
    return await _handleResponse(response);
}

async function updatePatientName(id, version, firstName, lastName) {
    var token = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id, version, firstName, lastName })
    };
    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/updatePatientName`, requestOptions);
    return await _handleResponse(response);
}

async function addPatientTray(trayDetails, patientId, pharmacyId) {
    var token = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ frequency: trayDetails.frequency, startDate: trayDetails.startDate, collectionSite: trayDetails.collectionSite, patientName: trayDetails.patientName, 
            pharmacistCheck: trayDetails.pharmacistCheck, notRequired: trayDetails.notRequired, enabled: trayDetails.enabled, trays: trayDetails.trays, patientId: patientId, pharmacyId })
    };
    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/addPatientTray`, requestOptions);
    return await _handleResponse(response);
}

async function updatePatientTray(trayDetails, patientId, pharmacyId) {
    var token = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ frequency: trayDetails.frequency, startDate: trayDetails.startDate, collectionSite: trayDetails.collectionSite, patientName: trayDetails.patientName, 
            pharmacistCheck: trayDetails.pharmacistCheck, notRequired: trayDetails.notRequired, enabled: trayDetails.enabled, trays: trayDetails.trays, MojoId: trayDetails.MojoId, MojoVersion: trayDetails.MojoVersion, 
            patientId: patientId, pharmacyId })
    };
    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/updatePatientTray`, requestOptions);
    return await _handleResponse(response);
}

async function addPatient(firstName, lastName, dob, phoneNumber, address, postcode, surgery, pharmacyId, email) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName, lastName, dob, phoneNumber, address, postcode, surgery, pharmacyId, email })
    };
    let response = await fetch(`https://${config.environment}-app-api-fa-remedi-pr.azurewebsites.net/api/addPatient`, requestOptions);

    let text = await response.text();
    const data = text;// && JSON.parse(text);

    if (!response.ok) {

        let error = (data && data.message) || response.statusText;

        if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
            window.location.reload(true);
        } else if(response.status === 400) {
            if(text.includes('EMAIL_EXISTS')) {
                error = 'This patient email is already registered'
            }
        }



        return {
            error: error,
            success: false
        }
    }

    return {
        success: true
    };
}


async function updateSurgeries(id, version, surgeries) {
    var token = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id, version, surgeries })
    };
    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/updateSurgeries`, requestOptions);
    return await _handleResponse(response);
}

async function updateAvatar(id, version, file) {
    var token = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: file
    };
    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/updateAvatar?id=${id}&version=${version}`, requestOptions);
    return await _handleResponse(response);
}

async function updateLogo(id, version, file) {
    var token = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: file
    };
    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/updateLogo?id=${id}&version=${version}`, requestOptions);
    return await _handleResponse(response);
}

async function sendPatientEmail(name, firstName, email, pharmacyId, subject, body) {
    var token = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ name, firstName, email, pharmacyId, subject, body })
    };

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/sendPatientEmail`, requestOptions);
    return await _handleResponse(response);
}

async function getOrdersToProcess(processDate) {
    let requestOptions = _buildRequestOptions();
    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/getOrdersToProcess?date=${processDate}`, requestOptions);
    let ordersToProcess = await _handleResponse(response);

    if (ordersToProcess) {
        return JSON.parse(ordersToProcess);
    }
    return null;
}

async function processOrders(prescriptions) {
    var token = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(prescriptions)
    };

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/processOrders`, requestOptions);
    return await _handleResponse(response);
}

async function getSupplierOrder(date) {
    let requestOptions = _buildRequestOptions();

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/getSupplierOrder?date=${date}`, requestOptions);
    let supplierOrder = await _handleResponse(response);

    if (supplierOrder) {
        return JSON.parse(supplierOrder);
    }
    return null;
}

async function getPharmacy() {
    let requestOptions = _buildRequestOptions();

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/getPharmacy`, requestOptions);
    let pharmacy = await _handleResponse(response);

    if (pharmacy) {
        return JSON.parse(pharmacy);
    }
    return null;
}

async function getRxOrder(date) {
    let requestOptions = _buildRequestOptions();

    let response = await fetch(`https://${config.environment}-portal-api-fa-remedi-pr.azurewebsites.net/api/getRxOrder?date=${date}`, requestOptions);
    let rxOrder = await _handleResponse(response);

    if (rxOrder) {
        return JSON.parse(rxOrder);
    }
}

async function _handleResponse(response) {
    let text = await response.text();
    const data = text;// && JSON.parse(text);
    console.log(text)
    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
            window.location.reload(true);
        } else if (response.status === 409) {
            return '409:' + text;
        }

        const error = (data && data.message) || response.statusText;
        return null;
    }
        
    return data;
}

function _buildRequestOptions() {
    if('token' in localStorage) {
        var token = localStorage.getItem('token');
    } else {
        logout();
        window.location.reload(true);
    }

    return {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };
}