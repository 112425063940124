/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import Select2 from "react-select2-wrapper";
import NotificationAlert from 'react-notification-alert';

import Regex from 'regex'

import { remediApi } from '../../../remediApi';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { isThisTypeNode } from "typescript";

class NewPatient extends React.Component {
    state = {
        id: "",
        version: "",
        patient: {
            firstName: "",
            firstNameState: null,
            lastName: "",
            lastNameState: null,
            email: "",
            emailState: null,
            dob: "",
            dobState: null,
            phoneNumber: "",
            phoneNumberState: null,
            address: "",
            addressState: null,
            postcode: "",
            postcodeState: null,
            surgery: "",
            surgeryState: null,
        },
        pharmacy: null,
        loading: false,
        editable: true,
    };

    _emailRegex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?)*$")
    _phoneRegex = new RegExp("^(((\\+|00)?44|0)([123578]{1}))(((\\d{1}\\s?\\d{4}|\\d{2}\\s?\\d{3})\\s?\\d{4})|(\\d{3}\\s?\\d{2,3}\\s?\\d{3})|(\\d{4}\\s?\\d{4,5}))$")

    async componentDidMount() {
        if('pharmacy' in localStorage) {
            var pharmacy = JSON.parse(localStorage.getItem('pharmacy'));
            console.log(pharmacy)
            this.setState({ pharmacy: pharmacy });
        }
    }

    _validateForm = async () => {
        let newState = this.state.patient;
        let patientValid = true;
        if (newState.firstName === "") {
            newState.firstNameState = "invalid";
            patientValid = false;
        } else {
            newState.firstNameState = "valid";
        }
        if (newState.lastName === "") {
            newState.lastNameState = "invalid";
            patientValid = false;
        } else {
            newState.lastNameState = "valid";
        }
        if (!this._emailRegex.test(newState.email)) {
            newState.emailState = "invalid";
            patientValid = false;
        } else {
            newState.emailState = "valid";
        }
        if (newState.dob === "") {
            newState.dobState = "invalid";
            patientValid = false;
        } else {
            newState.dobState = "valid";
        }
        if (!this._phoneRegex.test(newState.phoneNumber)) {
            newState.phoneNumberState = "invalid";
            patientValid = false;
        } else {
            newState.phoneNumberState = "valid";
        }
        if (newState.address === "") {
            newState.addressState = "invalid";
            patientValid = false;
        } else {
            newState.addressState = "valid";
        }
        if (newState.postcode === "") {
            newState.postcodeState = "invalid";
            patientValid = false;
        } else {
            newState.postcodeState = "valid";
        }
        if (newState.surgery === "") {
            newState.surgeryState = "invalid";
            patientValid = false;
        } else {
            newState.surgeryState = "valid";
        }
        this.setState({
            patient: newState
        });

        if (patientValid) {
            await this._add();
        }
    };

    _form = (e, stateName) => {
        let newState = this.state.patient;
        newState[stateName] = e.target.value;

        if (stateName === "email") {
            if (this._emailRegex.test(e.target.value)) {
                newState.emailState = "valid";
            } else {
                newState.emailState = "invalid";
            }
        } else if (stateName === "phoneNumber") {
            if (this._phoneRegex.test(e.target.value)) {
                newState.phoneNumberState = "valid";
            } else {
                newState.phoneNumberState = "invalid";
            }
        } else {
            if (e.target.value === "") {
                newState[stateName + "State"] = "invalid";
            } else {
                newState[stateName + "State"] = "valid";
            }
        }

        this.setState({
            patient: newState
        });
    };

    async _add() {
        this.setState({loading:true})
        var options = {};

        let result = await remediApi.addPatient(
            this.state.patient.firstName,
            this.state.patient.lastName,
            this.state.patient.dob,
            this.state.patient.phoneNumber,
            this.state.patient.address,
            this.state.patient.postcode,
            this.state.patient.surgery,
            this.state.pharmacy.MojoId,
            this.state.patient.email,
        );

        if (!result.success) {
            let options = {
                place: 'br',
                message: (
                    <div>
                        <div>
                            Failed to add new patient: {result.error}.
                        </div>
                    </div>
                ),
                type: "danger",
                autoDismiss: 7
            };
            this.refs.notify.notificationAlert(options);
            this.setState({loading:false})
            return;
        }

        options = {
            place: 'br',
            message: (
                <div>
                    <div>
                        Patient added successfully.
                    </div>
                </div>
            ),
            type: "success",
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        }
        await this.refs.notify.notificationAlert(options);
        await new Promise(r => setTimeout(r, 1000));
        this.props.history.goBack();
    }

    render() {
        return (
            <>
                <SimpleHeader name="Add Patient" parentName="Patients" />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <div className="card-wrapper">
                                <Card>
                                    <CardHeader>
                                        <span style={{ float: 'right' }}>
                                        {this.state.loading ?
                                            <Spinner color="primary" style={{ width: "1rem", height: "1rem", marginRight: "5px" }} /> :
                                            <span>
                                                <Button className="btn btn-default btn-sm btn-icon-only" onClick={async (e) => {
                                                    await this._validateForm();
                                                }}>
                                                    <span className="btn-inner--icon"><i className="fas fa-save"></i></span>
                                                </Button>
                                                <Button className="btn btn-info btn-sm btn-icon-only" onClick={async (e) => {
                                                    this.props.history.goBack()
                                                }}>
                                                    <span className="btn-inner--icon"><i className="fas fa-times"></i></span>
                                                </Button>
                                            </span>}
                                        </span>
                                    </CardHeader>
                                    <CardBody>
                                        <Form className="needs-validation" noValidate>
                                            <div className="form-row">
                                                <Col className="mb-3" md="4">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="validationCustom01"
                                                    >
                                                        First name
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        value={this.state.patient.firstName}
                                                        readOnly={!this.state.editable}
                                                        valid={
                                                            this.state.patient.firstNameState === "valid"
                                                        }
                                                        invalid={
                                                            this.state.patient.firstNameState === "invalid"
                                                        }
                                                        onChange={e =>
                                                            this._form(e, "firstName")
                                                        }
                                                    />
                                                </Col>
                                                <Col className="mb-3" md="4">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="validationCustom02"
                                                    >
                                                        Last name
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        value={this.state.patient.lastName}
                                                        readOnly={!this.state.editable}
                                                        valid={
                                                            this.state.patient.lastNameState === "valid"
                                                        }
                                                        invalid={
                                                            this.state.patient.lastNameState === "invalid"
                                                        }
                                                        onChange={e =>
                                                            this._form(e, "lastName")
                                                        }
                                                    />
                                                </Col>
                                            </div>
                                            <div className="form-row">
                                                <Col className="mb-3" md="4">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="validationCustom01"
                                                    >
                                                        Email Address
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        value={this.state.patient.email}
                                                        readOnly={!this.state.editable}
                                                        valid={
                                                            this.state.patient.emailState === "valid"
                                                        }
                                                        invalid={
                                                            this.state.patient.emailState === "invalid"
                                                        }
                                                        onChange={e =>
                                                            this._form(e, "email")
                                                        }
                                                    />
                                                </Col>
                                                <Col className="mb-3" md="4">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="validationCustom01"
                                                    >
                                                        Date of Birth
                                                    </label>
                                                    <Input
                                                        type="date"
                                                        value={this.state.patient.dob}
                                                        readOnly={!this.state.editable}
                                                        valid={
                                                            this.state.patient.dobState === "valid"
                                                        }
                                                        invalid={
                                                            this.state.patient.dobState === "invalid"
                                                        }
                                                        onChange={e =>
                                                            this._form(e, "dob")
                                                        }
                                                    />
                                                </Col>
                                                <Col className="mb-3" md="4">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="validationCustom02"
                                                    >
                                                        Phone Number
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        value={this.state.patient.phoneNumber}
                                                        readOnly={!this.state.editable}
                                                        valid={
                                                            this.state.patient.phoneNumberState === "valid"
                                                        }
                                                        invalid={
                                                            this.state.patient.phoneNumberState === "invalid"
                                                        }
                                                        onChange={e =>
                                                            this._form(e, "phoneNumber")
                                                        }
                                                    />
                                                </Col>
                                            </div>
                                            <div className="form-row">
                                                <Col className="mb-3" md="6">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="validationCustom03"
                                                    >
                                                        Address
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        value={this.state.patient.address}
                                                        readOnly={!this.state.editable}
                                                        valid={
                                                            this.state.patient.addressState === "valid"
                                                        }
                                                        invalid={
                                                            this.state.patient.addressState === "invalid"
                                                        }
                                                        onChange={e =>
                                                            this._form(e, "address")
                                                        }
                                                    />
                                                </Col>
                                                <Col className="mb-3" md="6">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="validationCustom03"
                                                    >
                                                        Postcode
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        value={this.state.patient.postcode}
                                                        readOnly={!this.state.editable}
                                                        valid={
                                                            this.state.patient.postcodeState === "valid"
                                                        }
                                                        invalid={
                                                            this.state.patient.postcodeState === "invalid"
                                                        }
                                                        onChange={e =>
                                                            this._form(e, "postcode")
                                                        }
                                                    />
                                                </Col>
                                            </div>
                                            <div className="form-row">
                                                <Col className="mb-3" md="4">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="validationCustom02"
                                                    >
                                                        Surgery
                                                    </label>
                                                    <Select2
                                                        required
                                                        className="form-control select-invalid"
                                                        defaultValue={this.state.patient.surgery}
                                                        options={{
                                                            placeholder: "Select"
                                                        }}
                                                        readOnly={!this.state.editable}
                                                        data-minimum-results-for-search="Infinity"
                                                        data={this.state.pharmacy ? this.state.pharmacy.surgeries.map((sug, key) => {
                                                            return { id: sug.name, text: sug.name}
                                                        }) : []}
                                                        onChange={e =>
                                                            this._form(e, "surgery")
                                                        }
                                                    />
                                                </Col>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </Row>
                </Container>
                <div style={{ zIndex: 1051, position: 'fixed' }}>
                    <NotificationAlert ref="notify" />
                </div>
            </>
        );
    }
}

export default NewPatient;
